<template>
  <div id="Community" 
    style="width:100%; margin:20px;" 
  >
    
      <center>
        <b-card
          style="max-width: 40rem; margin-top:3em;"
          class="mb-2"
        >

          <b-card-img 
            id="displayedImg"
            :src="getPicturePath(community.picture)" 
            style="max-width: 150px" 
            alt="Community"
            top>
          </b-card-img>

          <div v-if="isLogged">

            <h3>{{community.name}}</h3>

            <b-input-group prepend="Name">
              <b-form-input
                v-model="name"
                type="text"
                :state="validForm"
              >
              </b-form-input>
            </b-input-group>


            <b-input-group prepend="Description" style="margin-top:1em">
              <b-form-textarea
                v-model="community.description"
                type="text"
              >
              </b-form-textarea>
            </b-input-group>

            <b-input-group prepend="Community picture" style="margin-top:1em">
              <b-form-file
                id="inputImgFileForm"
                v-model="inputImgFile"
                :state="imageValidation"
                placeholder="Select or drop img (.jpg, .png, .gif) here"
                drop-placeholder="Drop an image (.jpg, .png, .gif) here ..."
                accept=".jpg, .png, .gif"
                @change="previewInputImgFile()"
                >
              </b-form-file>
            </b-input-group>

            <!-- Prop `add-on-change` is needed to enable adding tags vie the `change` event -->
            <!-- The <template #first> is required to prevent bugs with Safari -->
            <b-input-group prepend="Model packages" style="padding-top: 1em; text-align:left;">
              <b-form-tags
                id="tags-component-select"
                v-model="community.modelPackages"
                add-on-change
                no-outer-focus
              >
                <template v-slot="{ tags, inputAttrs, inputHandlers, disabled, removeTag }">
                  <ul v-if="tags.length > 0" class="list-inline d-inline-block mb-2">
                    <li v-for="tag in tags" :key="tag" class="list-inline-item">
                      <b-form-tag
                        @remove="removeTag(tag)"
                        :title="tag"
                        :disabled="disabled"
                      >{{ tag }}</b-form-tag>
                    </li>
                  </ul>
                  <b-form-select
                    v-bind="inputAttrs"
                    v-on="inputHandlers"
                    :disabled="disabled || models.length === 0"
                    :options="models"
                  >
                    <template #first>
                      <option disabled value="">Choose a model...</option>
                    </template>
                  </b-form-select>
                </template>
              </b-form-tags>
            </b-input-group>
            

            <b-input-group prepend="Administrators" style="padding-top: 1em; text-align:left;">
              <b-form-tags class="form-control"  style="background: white;"
                  v-model="community.administrators"
                  separator=","
                  placeholder="Enter e-mail separated by comma"
                  invalid-tag-text="Please enter a valid email address"
                  :tag-validator="emailValidator"
                  duplicate-tag-text="duplicated e-mail"
                ></b-form-tags>
            </b-input-group>

            <b-button v-if="createMode" variant="primary" @click="submitCommunity()" style="margin-top:1em">
              Create
            </b-button>
            <b-button v-if="editMode" variant="primary" @click="submitCommunity()" style="margin-top:1em">
              Update
            </b-button>
            <b-button @click="$router.go(-1)" style="margin-top:1em;margin-left:1em">
              Cancel
            </b-button>
          </div>
          <div v-if="errorMsg">
            <p style="color:red;">
              {{errorMsg}}
            </p>
          </div>
          
        </b-card>
      </center>
  
</div>

</template>
<script>
const path = require('path');

export default {
  name: "EditCommunity",

  data() {
    return {
      name: null,
      //description: "",
      regEmail: /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
      community: {},
      inputImgFile: null,
      defaultPicture:"community_iconfinder_128px.png",
      maxSize:5000000, // 5Mb
      submitted:false,
      createMode: false,
      editMode: false,
      errorMsg:null
    };
  },

  async mounted() {
    if (this.$route.name == "NewCommunity"){
      this.createMode = true
    }
    else if (this.$route.name == "EditCommunity"){
      this.editMode = true
    }

    if (typeof this.$route.params.community !== 'undefined'){
      this.community = this.communities.find(c=> c.name === this.$route.params.community)
      this.name = this.community.name
    } else {
      this.editMode = false
      this.createMode = true
      this.community = {
        name: "",
        description: "",
        picture: this.defaultPicture,
        administrators: [],
        modelPackages: [],
      }
    }

  },

  computed: {
    isDataLoaded() {
      return this.$store.getters.getIsDataLoaded
    },
    models() {
      return this.$store.getters.getModels.filter(m=>m.isPublic).map(m=>m.id).sort()
    },
    communities() {
      return this.$store.getters.getCommunities
    },
    user() {
      return this.$store.getters.getUser
    },
    isLogged(){
      return this.user !== null
    },
    imageValidation(){
      return !this.inputImgFile || this.inputImgFile && this.inputImgFile.size < this.maxSize
    },
    validName(){
      if (this.name === "" || !this.name || this.name.length === 0) return false
      return true
    },
    validUniqueName(){
      console.log('unique', this.communities.filter(c=> c.name.toLowerCase() === this.name.toLowerCase()))
      return  this.$route.params.community === this.name 
        || this.name && this.communities.filter(c=> c.name.toLowerCase() === this.name.toLowerCase()).length === 0 
    },
    validForm(){
      return this.editMode || this.validName && this.validUniqueName
    },
  },

  methods: {
    previewInputImgFile() {
      var file = document.getElementById('inputImgFileForm').files[0];
      if (file.size > this.maxSize) {
        this.fileErrorMsg = 'file too big, max size: '+this.maxSize/1000000+ ' Mo'
        return;
      }
      var reader  = new FileReader();
      reader.onload = function(e)  {
          var image = document.getElementById("displayedImg");
          image.src = e.target.result;
        }
      reader.readAsDataURL(file);
     },

    getPicturePath(picture){
      return `${this.$baseUrl}/community_images/` + picture
    },
    
    async submitCommunity(){
      this.errorMsg = ""
      let goOn = true
      let image = this.inputImgFile;

      if(!this.imageValidation){
        this.errorMsg = "image file too big"
        goOn = false
      }
      if(!this.validName){
        this.errorMsg = "some required fields are empty"
        goOn = false
      }
      if(!this.validUniqueName){
        this.errorMsg = "This community name already exists"
        goOn = false
      }

      if (!goOn) return

      if (image != null){
        var extension = "." + image.name.split('.').pop();
        var name = path.basename(image.name, extension)
        this.community.picture = name + Date.now() + extension
      }

      if (this.community.createdBy == null){
        this.community.createdBy = this.$store.getters.getUser.email
      }
      if(!this.community.administrators.includes(this.$store.getters.getUser.email)) {
        this.community.administrators.push(this.$store.getters.getUser.email)
      }

      this.community.name = this.name

      await this.$store.dispatch('createOrUpdateCommunity',{image,community:this.community})
      this.submitted =true;
      this.$router.push("/Communities")
    },

    emailValidator(email){
      return (email == "")? "" : (this.regEmail.test(email)) ? true : false;
    }

  },

  watch:{
  }

};
</script>

<style scoped>
.fake{
  text-align: left;
}

</style>
