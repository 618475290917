<template>
  <div id="app" >

    <header id="header">
      <div id="navbar"  >
        <Navbar />
      </div>
    </header>
    
    <main id="main">
      <notifications position="bottom right"/>
      <router-view />
    </main>

    <footer > 
      <Footer />
    </footer>
    
  </div>
</template>

<script>
import Navbar from "./Navbar";
import Footer from "./Footer";

export default {
  name: 'App',
  components: {    
    Navbar,
    Footer,
  },
  computed:{
    isDataLoaded() {
      return this.$store.getters.getIsDataLoaded
    },
    user(){
      return this.$store.getters.getUser
    },
    error(){
      return this.$store.state.error
    },
  },
  async created() {
    if (!this.isDataLoaded) {
      await this.$store.dispatch('loadAllCommunities')
      await this.$store.dispatch('loadPublicModels')
      
      // note: not working, this.user not loaded at the time
      // profile and private models loaded when needed == profile page

      //if (this.user) {
//
      //  console.log('APP load profile and private models')
      //  this.$store.dispatch('loadUserProfile', this.user.userName);
      //  this.$store.dispatch('loadUserPrivateModels');
      //}

      this.$store.commit('setIsDataLoaded',true)
    }
  }
}
</script>

<style scoped>
 #app { 
  font-family: Avenir, Helvetica, Arial, sans-serif;
  text-align: center;
  overflow-x: clip;
 }

 #main{
   width: 100%;
   margin-bottom: 100px;
   padding-top: 80px;
 }
 

 



</style>
