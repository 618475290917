const FormData = require('form-data');
const axios = require('axios');
import config from '../config'

var url = `${config.server.protocol}://${config.server.host}:${config.server.port}/server/`;


class FileServices {

    static async downloadZip(zipName){
        return new Promise((resolve, reject) => {
            try { 
                axios.get(url + "files/downloadZip", {params:{zipName: zipName}, responseType: 'blob'}).then(res => {
                    const downloadUrl = window.URL.createObjectURL(new Blob([res.data]));
                    const link = document.createElement('a');
                    link.href = downloadUrl;
                    link.setAttribute('download', zipName); //any other extension
                    document.body.appendChild(link);
                    link.click();
                    link.remove();

                    resolve();
                })
            } catch (err) { 
                console.error(err);
                reject(err);
            }
        })
    }


    static uploadZip = async (file, modelMetaDataPart) =>{
        const formData = new FormData();
        formData.append(file.name, file);
        formData.append('AddedMetadata', JSON.stringify(modelMetaDataPart));

        const res = await axios.post(url+'files/uploadZip', formData, {
            // headers: formData.getHeaders()
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `${sessionStorage.getItem("token")}`
            }
        })
        return res.data
    }


    static loadCodeFile = async (packageName, unitModelName, type, lang, extension) =>{
        return new Promise( (resolve, reject) => {
            
            try { 
                const params={packageName, unitModelName, type, lang, extension}
                axios.get(url + "files/loadCodeFile",{params}).then(res => {
                    resolve(res.data) ;
                })
            } catch (err) { 
                console.error(err);
                reject(err);
            }
        })
    }
}


export default FileServices;
