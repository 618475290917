import { render, staticRenderFns } from "./AMEI_Events.vue?vue&type=template&id=fd2ef484&scoped=true"
import script from "./AMEI_Events.vue?vue&type=script&lang=js"
export * from "./AMEI_Events.vue?vue&type=script&lang=js"
import style0 from "./AMEI_Events.vue?vue&type=style&index=0&id=fd2ef484&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fd2ef484",
  null
  
)

export default component.exports