<template>

  <div v-if="isDataLoaded" style="display:block; margin-bottom:100px" class="mx-auto col-lg-7" >

    <center style="margin-top:20px;"><b>
      Welcome to Crop2ML, a repository of biophysical processes involved in crop growth and development.
      Find relevant crop models in the catalog below or update your own models.
    </b></center>

    <b-button class="mt-2 mb-4" variant="outline-success" :to="'/Submit'">+ Contribute</b-button>
   
    <VoerroTagsInput element-id="tags"
      v-model="searchKeywordsTransform"
      :existing-tags="allKeywords"
      placeholder="Enter keywords to search through the catalog."
      :only-existing-tags="true"
      :typeahead="true"
      typeahead-style="badges"
      :typeahead-hide-discard="true"
      id-field="value"
      text-field="value">
    </VoerroTagsInput>

    
    <div id="my-models">    
    <model-card       
      class="cardWidth mt-4 "
      v-for="(model, key) of paginatedModels"
      :key="key"
      :model="model"
      :version="model.versions[model.versions.length -1]"
      :parent="'repository'"
    />
    </div>
    <b-pagination
      v-model="currentPage"
      :total-rows="filteredModels.length"
      :per-page="perPage"
      align="right"
      aria-controls="my-models"
    ></b-pagination>

    
  </div>
  
</template>
<script>

// import StarRating from 'vue-star-rating'
import VoerroTagsInput from '@voerro/vue-tagsinput';
import ModelCard from './components/models/ModelCard.vue';

export default {
  name: 'Repository',

  components: {
    ModelCard,
    VoerroTagsInput
  },
  data() {
    return {
      currentRating: "No Rating",
      currentSelectedRating: "No Current Rating",
      showOnlyPersoModels: false,
      searchKeywords: [],
      sortByName: false,
      perPage: 20,
      currentPage: 1,
    }
  },

  created() {
  },

  mounted() {
    if(this.$route.query.keyword) {
      this.searchKeywords = [{value:this.$route.query.keyword}]
    } else {
      this.searchKeywords = this.$store.getters.getCatalogFilters
    }
  },

  computed:{
    isDataLoaded() {
      return this.$store.getters.getIsDataLoaded
    },
    models() {
      return this.$store.getters.getModels.filter(m => m.isPublic)
    },
    filteredModels() {
      let filteredModels
      if (!this.models)
        return []
      if (this.searchKeywords.length != 0) {
        let keywordsFormatted = []
        for (const k of this.searchKeywords) {
          keywordsFormatted.push(k.value)
        }
        filteredModels = this.models.filter(m => {
          let match = false
          m.versions.forEach(v => {
            if (v.AddedMetadata.keywords.some(k => keywordsFormatted.indexOf(k) >= 0))
              match = true
          })
          return match
        })
      } else {
        filteredModels = this.models
      }
      if (this.showOnlyPersoModels) {
        filteredModels = filteredModels.filter(m => this.isAdmin(m) || this.isEditor(m))
      }
      if (this.sortByName){
        return filteredModels.sort((a,b) => (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0))
      } else {
        return filteredModels.sort((a,b) => (a.createdAt > b.createdAt) ? 1 : ((b.createdAt > a.createdAt) ? -1 : 0))
      }
    },
    allKeywords() {
      return this.$store.getters.getKeywords
    },
    searchKeywordsTransform: {
      get: function () {
        return this.searchKeywords;
      },
      set: function (words) {
        this.searchKeywords = words
        this.$store.commit('setCatalogFilters', this.searchKeywords)
      }
    },
    paginatedModels() {
      const start = (this.currentPage - 1) * this.perPage;
      const end = start + this.perPage;
      return this.filteredModels.slice(start, end);
    },
  },
  methods: {

    isAdmin(model){
      return (this.$store.getters.getUser != null &&
      model.administratorsUsernames.includes(this.$store.getters.getUser.userName))
      //this.$store.getters.getUser.associatedModels.findIndex(m => (m.modelId == this.model.id && m.role == "administrator")) != -1)
    },

    isEditor(model){
      return (this.$store.getters.getUser != null &&
      model.editorsUsernames.includes(this.$store.getters.getUser.userName))
      //this.$store.getters.getUser.associatedModels.findIndex(m => (m.modelId == this.model.id && m.role == "editor")) != -1)
    },
    
  },

  watch:{
  },

}
</script>


<style scoped>

@import "https://cdn.jsdelivr.net/npm/@voerro/vue-tagsinput@2.7.1/dist/style.css";

p{
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 2px;
  padding-right: 2px;
}


</style>
