<template>

  <div class="container">
    <b-row>
      <b-col>
        <h1 class="heading">
          Supported modeling platforms and crop models
        </h1>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <p>The current version of Crop2ML framework supports the exchange of model components between the following
          modeling platforms and crop models. </p>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-list-group flush>
          <b-list-group-item>
            <b-row align-v="center">
              <b-col md="auto">
                <!-- Avatar -->
                <b-img alt="Image placeholder" src="/images/APSIM_LOGO.svg" class="avatar" width="180px"/>
              </b-col>
              <b-col class="ml--2">
                <div class="d-flex justify-content-between align-items-center">
                  <div>
                    <h4 class="mb-0 text-sm">APSIM</h4>
                  </div>

                </div>
                <p class="text-sm-left mb-0">The Agricultural Production Systems sIMulator (APSIM) is an advanced platform for modelling and simulation of agricultural systems.
                  It contains a suite of modules that enable the simulation of systems for a diverse range of plant, animal, soil, climate and management interactions.
                  APSIM is undergoing continual development, with new capability being added.
                  Its development and maintenance is underpinned by rigorous science and software engineering standards.</p>
              </b-col>
            </b-row>
          </b-list-group-item>
          
          <b-list-group-item>
            <b-row align-v="center">
              <b-col md="auto">
                <!-- Avatar -->
                <b-img alt="Image placeholder" src="/images/bioma_logo.png" class="avatar" width="180px"/>
              </b-col>
              <b-col class="ml--2">
                <div class="d-flex justify-content-between align-items-center">
                  <div>
                    <h4 class="mb-0 text-sm">BioMA</h4>
                  </div>

                </div>
                <p class="text-sm-left mb-0">BioMA (Biophysical Models Applications) as a follow up of the APES
                  environment is a software framework designed and developed by The Joint Research Center (JRC) of the
                  European Commission. It is used for running, calibrating, and improving modeling solutions based on
                  biophysical models in the domains of agriculture and environment. BioMA offers a modular and flexible
                  architecture in three independent layers (model, component, and configuration).The framework is based
                  on framework-independent components, both for the modeling solutions and the graphical user's
                  interfaces. The goal is not only to provide a framework for model development and operational use but
                  also, and of no lesser importance, to provide a loose collection of objects re-usable either
                  standalone or in different frameworks. The software is developed using Microsoft C# language in the
                  .NET framework.</p>
              </b-col>
            </b-row>
          </b-list-group-item>

          <b-list-group-item>
            <b-row align-v="center">
              <b-col md="auto">
                <!-- Avatar -->
                <b-img alt="Image placeholder" src="/images/dssat_logo.png" class="avatar" width="180px"/>
              </b-col>
              <b-col class="ml--2">
                <div class="d-flex justify-content-between align-items-center">
                  <div>
                    <h4 class="mb-0 text-sm">DSSAT</h4>
                  </div>

                </div>
                <p class="text-sm-left mb-0">DSSAT (Decision Support System for Agrotechnology Transfer) is software
                  application program that comprises dynamic crop growth simulation models for over 42 crops. DSSAT is
                  supported by a range of utilities and apps for weather, soil, genetic, crop management, and
                  observational experimental data, and includes example data sets for all crop models. DSSAT has been
                  applied to address many real-world problems and issues ranging from genetic modeling to on-farm and
                  precision management to regional assessments of the impact of climate variability and climate change.
                  DSSAT has been used for more than 30 years by researchers, educators, consultants, extension agents,
                  growers, private industry, policy and decision makers, and many others in over 174 countries
                  worldwide.</p>
              </b-col>
            </b-row>
          </b-list-group-item>

          <b-list-group-item>
            <b-row align-v="center">
              <b-col md="auto">
                <!-- Avatar -->
                <b-img alt="Image placeholder" src="/images/openAlea_logo.png" class="avatar" width="180px"/>
              </b-col>
              <b-col class="ml--2">
                <div class="d-flex justify-content-between align-items-center">
                  <div>
                    <h4 class="mb-0 text-sm">OpenAlea</h4>
                  </div>

                </div>
                <p class="text-sm-left mb-0">OpenAlea is an open source project primarily aimed at the plant research community. It is a distributed collaborative effort to develop Python libraries and tools that address the needs of current and future works in Plant Architecture modeling. OpenAlea includes modules to analyze, visualize and model the functioning and growth of plant architecture.</p>
              </b-col>
            </b-row>
          </b-list-group-item>

          <b-list-group-item>
            <b-row align-v="center">
              <b-col md="auto">
                <!-- Avatar -->
                <b-img alt="Image placeholder" src="/images/record_logo.png" class="avatar" width="180px"/>
              </b-col>
              <b-col class="ml--2">
                <div class="d-flex justify-content-between align-items-center">
                  <div>
                    <h4 class="mb-0 text-sm">RECORD</h4>
                  </div>

                </div>
                <p class="text-sm-left mb-0">RECORD (REnovation and COORDination of agroecosystems) modelling platform aims at providing different services for building, simulating, and analyzing models in the context of agroecosystems. It uses the Virtual Laboratory Environment (VLE) simulation engine, a generic modeling, simulation, and analysis environment based on the Discrete Event System Specification (DEVS) formalism. A graphical user interface (gvle) provides user-friendly tools to write model specifications, generate source code in C++, or execute models and analyze their output. Legacy software code may also be used in RECORD.</p>
              </b-col>
            </b-row>
          </b-list-group-item>

          <b-list-group-item>
            <b-row align-v="center">
              <b-col md="auto">
                <!-- Avatar -->
                <b-img alt="Image placeholder" src="/images/simplace_logo.png" class="avatar" width="180px" />
              </b-col>
              <b-col class="ml--2">
                <div class="d-flex justify-content-between align-items-center">
                  <div>
                    <h4 class="mb-0 text-sm">SIMPLACE</h4>
                  </div>

                </div>
                <p class="text-sm-left mb-0">SIMPLACE (Scientific Impact assessment and Modelling Platform for Advanced Crop and Ecosystem management) has been developed as a flexible modeling platform that attempts to meet the various demands of three user groups, scientists, engineers and decision makers, within one system. This enables the different user groups to interact and bridge gaps. The platform operates with SimComponents as the smallest building blocks, which in most cases describe biophysical processes in the soil-plant-atmosphere system, which is described by combining several SimComponents through links established by input-output definitions. GroupComponents combine SimComponents into logical structures of components that belong together.  SimComponents algorithms are coded using object-oriented techniques in the programming language Java.</p>
              </b-col>
            </b-row>
          </b-list-group-item>

          <b-link target="_blank" href="https://www1.clermont.inra.fr/siriusquality/" class="list-group-item list-group-item-action">
            <b-row align-v="center">
              <b-col md="auto">
                <!-- Avatar -->
                <b-img alt="Image placeholder" src="/images/sq_logo.png" class="avatar" width="180px"/>
              </b-col>
              <b-col class="ml--2">
                <div class="d-flex justify-content-between align-items-center">
                  <div>
                    <h4 class="mb-0 text-sm">SiriusQuality</h4>
                  </div>

                </div>
                <p class="text-sm-left mb-0">SiriusQuality (SQ) is a process-based model that simulates the phenology and canopy development of small grain cereals and the fluxes of water, nitrogen and carbon in the soil-plant-atmosphere continuum in response to weather and crop management. SQ has been developed and calibrated for spring and winter bread wheat and durum wheat, but can also simulate the growth of most small grain cereals, including barley, oat, rice, and triticale. SQ provides a modern user friendly graphical interface that provides several tools to setup complex multi-runs and for parameter optimization (model calibration) and uncertainty and sensitivity analysis. SQ console (command-line interface) is also available.</p>
              </b-col>
            </b-row>
          </b-link>

          <b-list-group-item>
            <b-row align-v="center">
              <b-col md="auto">
                <!-- Avatar -->
                <b-img alt="Image placeholder" src="/images/stics_logo.png" class="avatar" width="180px"/>
              </b-col>
              <b-col class="ml--2">
                <div class="d-flex justify-content-between align-items-center">
                  <div>
                    <h4 class="mb-0 text-sm">STICS</h4>
                  </div>

                </div>
                <p class="text-sm-left mb-0">STICS (Simulateur mulTIdisciplinaire pour les Cultures Standard) is …..</p>
              </b-col>
            </b-row>
          </b-list-group-item>

        </b-list-group>
      </b-col>
    </b-row>
  </div>

</template>
<script>


export default {
  name: 'Crop2ML_Platforms',

  data() {
    return {}
  },

  components: {},

  async created() {

  },

  async mounted() {

  }

}
</script>

<style scoped>

ul.myListStyle {
  list-style-type: none;
}

#about {
  height: 80vh;
  width: 100%;
}

#recentModels {
  text-align: left;
}


</style>
