<template>
<div>
  <center>
    <b-card
      
      style="max-width: 20rem; margin-top:3em;"
      class="mb-2"
    >
      <b-card-img 
        src="/images/user_icon.png" 
        style="max-width: 100px" 
        alt="User"
        top>
      </b-card-img>

      <div v-if="$store.getters.getUser ===null">

        <h3>Sign in</h3>
        <b-form @keydown="handleKeyDown">
          <b-input-group prepend="Email">
            <b-form-input
              :placeholder="email"
              v-model="email"
              type="email"
              :state="emailValidator"
            >
            </b-form-input>
          </b-input-group>


          <b-input-group prepend="Password" style="margin-top:1em">
            <b-form-input
              :placeholder="password"
              v-model="password"
              type="password"
            >
            </b-form-input>
          </b-input-group>

          <b-button :href="'#'" variant="secondary"  @click="signIn" style="margin-top:1em">
            Submit
          </b-button>
        </b-form>

        <div v-if="signInErrorMsg">
          <p style="color:red;">
            {{signInErrorMsg}}
          </p>
        </div>

        

        <div class="row">
          <div class="col-sm-8">
            <p  style="text-align: left; margin-top:1em">
              <b-link :to="'/ForgotPassword'"> Forgotten password </b-link>
            </p>
          </div>
          <div class="col-sm-4">
            <p  style="text-align: right; margin-top:1em">
              <b-link :to="'/Register'"> Register</b-link>
            </p>
          </div>
        </div>
      
      </div>

    </b-card>
  </center>
</div>

</template>
<script>

export default {
  data() {
    return {
      email: "",
      password: "",
      signInErrorMsg:"",
      regEmail: /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
    };
  },

  mounted() {
    
  },

  computed:{
    emailValidator(){
      return (this.email == "")? false : (this.regEmail.test(this.email)) ? true : false;
    },
  },

  methods: {
    async signIn() {
      if (!this.emailValidator) {
        this.signInErrorMsg="Wrong email"
      }
      const credentials = {email:this.email, password:this.password}
      await this.$store.dispatch('signIn', credentials)
      // after login, go to 'redirect' page, or the previous one, or home
      this.$router.push(this.$router.currentRoute.query.redirect || this.$router.go(-1) || '/');
    },
    handleKeyDown(event) {
      if (event.key === "Enter" || event.code === "Enter") {
        this.signIn(); 
      }
    }
  },

  watch:{
    email(){
      this.signInErrorMsg=""
    },
    
    password(){
      this.signInErrorMsg=""
    },
  }
};
</script>

<style scoped>

</style>
