import { render, staticRenderFns } from "./CommunityDetails.vue?vue&type=template&id=2b9f91fc&scoped=true"
import script from "./CommunityDetails.vue?vue&type=script&lang=js"
export * from "./CommunityDetails.vue?vue&type=script&lang=js"
import style0 from "./CommunityDetails.vue?vue&type=style&index=0&id=2b9f91fc&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b9f91fc",
  null
  
)

export default component.exports