<template>

  <div class="container">
    <b-row>
      <b-col>
        <h1 class="heading">
          Events
        </h1>

      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <p style="text-align: justify;">
          The Agricultural Model Exchange Initiative is organizing workshops and training sessions during which
          participants are encouraged to actively engage and share their ideas.
        </p>
        <b-list-group flush>
          <div class="d-flex w-100 justify-content-between">
            <div>
              <div class="d-flex w-100 align-items-start">
                <h5 class="mb-1">
                  Bellow is a list of workshops organized by AMEI to advance the development of the
                  Crop2ML framework and tools:
                </h5>
              </div>
            </div>
          </div>
          <b-list-group-item class=" flex-column align-items-start py-4 px-4">
            <b-list-group>
              <b-list-group-item class="flex-column align-items-start">
                <p class="text-left mb-1">
                  Gainesville, USA – Feb. 2015
                </p>
              </b-list-group-item>
              <b-list-group-item class="flex-column align-items-start">
                <p class="text-left mb-1">
                  Toulouse, FR – Jul. 2016
                </p>
              </b-list-group-item>
              <b-list-group-item class="flex-column align-items-start">
                <p class="text-left mb-1">
                  Bologna, IT – Jul 2016
                </p>
              </b-list-group-item>
              <b-list-group-item class="flex-column align-items-start">
                <p class="text-left mb-1">
                  Paris, FR – Oct.2017
                </p>
              </b-list-group-item>
              <b-list-group-item class="flex-column align-items-start">
                <p class="text-left mb-1">
                  Rome, IR – Mar. 2018
                </p>
              </b-list-group-item>
              <b-list-group-item class="flex-column align-items-start">
                <p class="text-left mb-1">
                  Bonn, GE – Jul. 2018
                </p>
              </b-list-group-item>
              <b-list-group-item class="flex-column align-items-start">
                <p class="text-left mb-1">
                  Montpellier, FR – Nov. 2018
                </p>
              </b-list-group-item>
              <b-list-group-item class="flex-column align-items-start">
                <p class="text-left mb-1">
                  Toulouse, FR – Feb. 2019
                </p>
              </b-list-group-item>
              <b-list-group-item class="flex-column align-items-start">
                <p class="text-left mb-1">
                  Gainesville, US – Apr. 2019
                </p>
              </b-list-group-item>
              <b-list-group-item class="flex-column align-items-start">
                <p class="text-left mb-1">
                  Montpellier, FR – Oct.2019
                </p>
              </b-list-group-item>
              <b-list-group-item class="flex-column align-items-start">
                <p class="text-left mb-1">
                  Montpellier, FR – Feb. 2020
                </p>
              </b-list-group-item>
            </b-list-group>
          </b-list-group-item>
        </b-list-group>
        <p style="text-align: justify;">
          Materials developed for hands-on training workshops are available on the AMIE GitHub repository.
        </p>
        <p style="text-align: justify;">
          If you are interested in participating in our next workshop please <b-link :to="'/About/ContactUs'">contact us</b-link> and we will send you information
          when they are available.
        </p>
        <div>
          <b-button :to="'/About/ContactUs'" variant="outline-primary">Contact Us</b-button>
        </div>
      </b-col>
    </b-row>
  </div>

</template>
<script>


export default {
  name: 'AMEI_Events',

  data() {
    return {}
  },

  components: {},

  async created() {

  },

  async mounted() {

  }

}
</script>

<style scoped>

ul.myListStyle {
  list-style-type: none;
}

#about {
  height: 80vh;
  width: 100%;
}

#recentModels {
  text-align: left;
}


</style>
