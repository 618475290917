<template>
<div>
  <center>
    <b-card
      style="max-width: 20rem; margin-top:3em;"
      class="mb-2"
    >
      <b-card-img 
        src="/images/user_icon.png" 
        style="max-width: 100px" 
        alt="User"
        top>
      </b-card-img>

      <div v-if="$store.getters.getUser == null">
        <h3> You are signed out</h3> <br>
        <b-button variant="secondary" :to="'/signIn'" style="margin-top:1em">
          Sign in
        </b-button>
      </div>

    </b-card>
  </center>
</div>

</template>
<script>


export default {
  data() {
    return {
    };
  },

  mounted() {
    sessionStorage.removeItem("token");
    this.$store.commit('setUser', null)
    this.$store.commit('setUserProfile', null)
    const publicModels=this.$store.getters.getModels.filter(m=>m.isPublic)
    this.$store.commit('setModels',null)
    this.$store.commit('setModels',publicModels)
  },

  methods: {
  },

  watch:{
  }

};
</script>

<style scoped>

</style>
