<template>
  
  <div> 

      <h1 style="margin: 30px;">
        Welcome to the crop modeling community model repository
      </h1>
      <p style="text-align: justify; margin: 50px;">
        CropMRepository provides an accessible online location for storing and efficiently retrieving and sharing simulation models of soil and crop growth biophysical processes. Models are stored in the open-source format <b-link :to="'/Crop2ML/WhatIsCrop2ML'">Crop Modeling MetaLanguage (Crop2ML)</b-link> and supported by the <b-link :to="'/AMEI/Vision'"> Agricultural Model Exchange Initiative (AMEI). </b-link> 
        <br><br>
        CropMRepository and Crop2ML follow the Minimum Information Required in the Annotation of Models (<b-link href="https://co.mbine.org/standards/miriam">MIRIAM</b-link>) and the Open Modeling Foundation (<b-link href="https://openmodelingfoundation.github.io/">OMF</b-link>) sets of guidelines that define how a model should be encoded, annotated, and documented in order to be successfully distributed, exchanged and ultimately reused.
        <br><br>
        All models are provided under an open-source license. This means that the models are available freely for use, modification, and distribution, to all users.
        <br><br>
        The models deposited in CropMRepository are <b-link :to="'/About/HowToCite'">citable</b-link> by using the unique and perennial model identifier assigned to each model.
        <br><br>
        Browse or search through the <b-link :to="'/Repository'">catalog</b-link> of models below, download models or <b-link :to="'/Submit'">submit</b-link> your own models.
      </p>
      <p>
        CropMRepository is registered with <b-link href="https://fairsharing.org/3806" target='_blank'>FAIRsharing.org</b-link> and <b-link href="https://registry.identifiers.org/registry/crop2ml" target='_blank'>Identifiers.org</b-link>
      </p>
      <div class="row" style="justify-content: center;"> 
        <div class="col-lg-2">
          <b-link href="https://fairsharing.org/3806" target="_blank" class="col-sm-4 btn-floating btn-sm btn-fb mx-1 "><i><img height="50px" src="/images/fairsharing.png" ></i></b-link>
        </div>
        <div class="col-lg-2">
          <b-link href="https://registry.identifiers.org/registry/crop2ml" target="_blank" class="col-sm-4 btn-floating btn-sm btn-fb mx-1 "><i><img height="50px" style="alignleft: 0" src="/images/identifiers.jpg" ></i></b-link>
        </div>
      </div>

    
  </div>
  
   
</template>
<script>



export default {
  name: 'Home',

   data() {
      return {
        
      }
    },

  components: {
    

  },

  async created() {
  },

  async mounted() {
    
  }

}
</script>

<style scoped>

</style>
