const axios = require('axios');
import config from '../config'

var url = `${config.server.protocol}://${config.server.host}:${config.server.port}/server/`;

class ModelServices{

    static loadPublicModels = async () =>{
        const res = await axios.get(url + "models/getPublicModels")
        return res.data
        //new Promise((resolve, reject) => {
        //    try { 
        //        axios.post(url + "models/getPublicModels").then(res => {
        //            resolve(res.data) ;
        //        })
        //    } catch (err) { 
        //        console.error(err);
        //        reject(err);
        //    }
        //})
    }
    
    static loadUserPrivateModels = async () =>{
        const res = await axios.get(url + "models/getUserPrivateModels",{headers: {
            'Authorization': `${sessionStorage.getItem("token")}`
        }})
        return res.data
    }

    static loadPrivateModelById = async(id) => {
        const res = await axios.get(url + "models/getPrivateModelById", { params:{id:id}})
        return res.data
    }
    /*static loadPrivateModelById = (id) =>{
        return new Promise((resolve, reject) => {
            
            axios.get(url + "models/getPrivateModelById", { params:{id:id}})
                .then(res => {
                    console.log('axios res', res)
                    resolve(res.data) ;
                })
                .catch(error => {
                    if (error.response && error.response.status === 404) {
                      console.error('Erreur 404 : Ressource non trouvée.');
                    } else {
                      console.error('Erreur :', error.message);
                    }
                    reject(error)
                  });
        })
    }
    */

    static async saveModel (model, isUploaded, file){
        const formData = new FormData();
        formData.append("picture", file);
        let body ={
            model: model,
            isUploaded: isUploaded,
        }
        formData.append("body", JSON.stringify(body));
        return await axios.post(url + "models/saveModel", formData, {
            // headers: formData.getHeaders()
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `${sessionStorage.getItem("token")}`
            }
        })
        
    }

    static async updateModel (modelid, payload){
        let apiRoute = "models/updateModel"
        let body ={
            modelid: modelid,
            payload: payload
        }
        // update as anonymous only for view and downloads
        if (payload.views || payload.downloads) {
            apiRoute = "models/updateModelAsAnonymous"
        }
        const res = await axios.post(url + apiRoute, body, {
            headers: {
                'Authorization': `${sessionStorage.getItem("token")}`
                }
        })
        return res.data
    }

    static async deleteModelById (modelid, version, user){
        let body ={
            modelid: modelid,
            version: version,
            user: user
        }
        const res = await axios.post(url + "models/deleteModelById", body, {
            headers: {
                'Authorization': `${sessionStorage.getItem("token")}`
                }
        })
        return res.data
    }
}

export default ModelServices