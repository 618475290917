<template>
  <div v-if="isDataLoaded" class="container mt-4">
    <b-row>
      <!-- Colonne de gauche -->
      <div class="col-md-3">
        <div class=" mb-4">
          <img :src="getPicturePath('user', user.picture)" class="card-img-top" alt="User photo" />
          <div class="card-body">
            <h3 class="card-title">{{ user.userName }}</h3>
            <b-button v-if="isLoggedUserProfile" :to="'/EditProfile'" style="margin-top:1em">
              Edit profile
            </b-button>
            <b-button v-else-if="loggedUser" style="margin-top:1em" v-b-modal="'contact-modal'" >
              Contact user
            </b-button>
            <p >{{user.firstName}} {{user.lastName}}</p>
            <p v-if="user.shortBio">{{user.shortBio}}</p>
            <p v-if="user.homePage"><b-link :to="user.homePage" target="_blank">{{user.homePage}}</b-link></p>
            <p v-if="user.institution">work at: {{user.institution}}</p>
            <p v-if="user.department">department: {{user.department}}</p>
            <p v-if="user.city">live in: {{user.city}}, {{user.country}}</p>
            <p v-if="user.keywords.length>0">
              <strong>keywords:({{user.keywords.length}})</strong>              
            </p>
            <b-badge  v-for="(keyword,i) in user.keywords" :key="i" variant="secondary" style="margin-right:3px;">
              {{keyword}}             
            </b-badge>
          </div>
        </div>
      </div>

      <b-modal 
        id="contact-modal" 
        title="Contact User"
        @ok="contactUser"
          >
            <b-form-group id="input-group-1" class="thisishoney" label="Name" label-for="name">
              <b-form-input id="name" v-model="form.honeyName" autocomplete="off"  ></b-form-input>
            </b-form-group>
            <b-form-group id="input-group-1" class="thisishoney" label="Email" label-for="email">
              <b-form-input id="email" v-model="form.honeyEmail" autocomplete="off"  ></b-form-input>
            </b-form-group>
            
            <b-form-group id="input-group-1" label="Subject" label-for="input-1">
              <b-form-input id="input-1" v-model="form.subject" required  maxlength="100"></b-form-input>
            </b-form-group>
            <b-form-group id="input-group-2" label="Message" label-for="input-2">
              <b-form-textarea id="input-2" v-model="form.message" required  ></b-form-textarea>
            </b-form-group>
      </b-modal>

      <!-- Colonne de droite -->
      <div class="col-md-9">
        <b-row>
          <p class="card-text">
            <strong>My models ({{userModels.length}})</strong>
          </p>
        </b-row>
        <b-row>
          <model-card 
            class="text-left col-md-6 mb-4"
            v-for="(model, key) of userModels"
            :key="key"
            :model="model"
            :version="model.versions[model.versions.length -1]"
            :parent="'profile'"
          />
        </b-row>
        <b-row>
          <p class="card-text">
            <strong>Starred models ({{modelsStarred.length}})</strong>
          </p>
        </b-row>
        <b-row>
          <model-card 
            class="text-left col-md-6 mb-4"
            v-for="(model, key) of modelsStarred"
            :key="key"
            :model="model"
            :version="model.versions[model.versions.length -1]"
            :parent="'profile'"
          />
        </b-row>

        <b-row>
          <p class="card-text">
            <strong>My communities ({{communitiesAdministrated.length}})</strong>
          </p>
        </b-row>
        <b-row>
          <community-card 
              class="text-center col-md-6 mb-4"
              v-for="(community, key) of communitiesAdministrated"
              :key="key"
              :community="community"
              :parent="'profile'"
            />
          
        </b-row>
        <b-row>
          <p class="card-text">
            <strong>Starred communities ({{communitiesStarred.length}})</strong>
          </p>
        </b-row>
        <b-row>
          <community-card 
              class="text-center col-md-6 mb-4"
              v-for="(community, key) of communitiesStarred"
              :key="key"
              :community="community"
              :parent="'profile'"
            />
          
        </b-row>
          
          
        
      </div>
    </b-row>
  </div>
</template>

<script>
import ModelCard from '../models/ModelCard.vue';
import CommunityCard from '../community/CommunityCard.vue';
import AuthServices from "@/services/AuthServices";

export default {
  name: "Profile",
  components: {
    ModelCard, 
    CommunityCard
  },

  props: {
  },
  data() {
    return {
      showEmail:true,
      defaultPicture:"images/user_iconfinder_128px.png" ,
      openContactModal:false,
      form: {
        email: '',
        name: '',
        subject: '',
        message: ''
      },
    }
  },
  async mounted(){
    await this.$store.dispatch('loadUserProfile', this.$route.params.username);
    if(this.loggedUser && this.privateModels.length===0) {
      this.$store.dispatch('loadUserPrivateModels');
    }
  },
  computed: {
    isDataLoaded() {
      return this.$store.getters.getIsDataLoaded
    },
    loggedUser(){
      return this.$store.getters.getUser
    },
    user(){
      return this.$store.getters.getUserProfile
    },
    isLoggedUserProfile(){
      return this.loggedUser && this.loggedUser.userName === this.$route.params.username
    },
    models(){
      return this.$store.getters.getModels
    },
    modelsAdministrated(){
      return this.models.filter(m => m.administratorsUsernames && m.administratorsUsernames.includes(this.user.userName))
    },
    modelsEdited(){
      return this.models.filter(m => m.editorsUsernames && m.editorsUsernames.includes(this.user.userName))
    },
    modelsStarred(){
      return this.models.filter(m => m.starredByUsernames && m.starredByUsernames.includes(this.user.userName))
    },
    userModels(){
      return this.modelsAdministrated.concat(this.modelsEdited)
    },
    privateModels(){
      return this.modelsAdministrated.filter(m => !m.isPublic)
    },
    communities(){
      return this.$store.getters.getCommunities
    },
    communitiesAdministrated(){
      return this.communities.filter(c => c.administratorsUsernames && c.administratorsUsernames.includes(this.user.userName))
    },
    communitiesStarred(){
      return this.communities.filter(c => c.starredByUsernames && c.starredByUsernames.includes(this.user.userName))
    }
  },
  methods: {
    getPicturePath(type, picture){
      if (!picture) return this.defaultPicture
      return `${this.$baseUrl}/${type}_images/` + picture
    },
    async contactUser() {
      if (this.form.name || this.form.email) {
        this.openContactModal=false
        return;
      }
      // Build contact object
      let contact = {
        from: this.loggedUser.email,
        to: this.user.userName,
        subject: this.form.subject,
        message: this.form.message
      }
      // Send form to server
      const res = await AuthServices.contactUser(contact)
      if(res.success) {
        this.isSending = false;
        this.$store.dispatch('showSuccess','email sent successfully')
        this.openContactModal=false
        this.form= {
          email: '',
          name: '',
          subject: '',
          message: ''
        }
      }else{
        //this.signInErrorMsg = res.errorMsg
        this.$store.dispatch('showError',res)
      }
    }
  },
  watch: { 
  '$route.params.username': function(username) {
    //reload userProfile if was any user then own profile
    this.$store.dispatch('loadUserProfile', username);
  }
}
};
</script>

<style scoped>

p{
  margin-bottom:2px;
  padding-left: 2px;
  padding-right: 2px;
}

.thisishoney{
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        height: 0;
        width: 0;
        z-index: -1;
    }

</style>
