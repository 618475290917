<template>
  
  <b-navbar class="topnav" toggleable="lg" type="dark" variant="dark" fixed="top" >
    <b-navbar-brand :to="'/Home'">CropMRepository</b-navbar-brand>
    <b-img height="50" width="70" src="/images/logoAMEI-light.jpg" alt=""></b-img>
    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav>
        <b-nav-item :to="'/Home'">Home</b-nav-item>
        <b-nav-item :to="'/Repository'">Catalog</b-nav-item>
        <b-nav-item :to="'/Communities'">Communities</b-nav-item>
        <b-nav-item :to="'/Submit'">Submit</b-nav-item>
        <b-nav-item-dropdown text="Crop2ML" >
          <b-dropdown-item :to="'/Crop2ML/WhatIsCrop2ML'">What is Crop2ML ?</b-dropdown-item>
          <b-dropdown-item :to="'/Crop2ML/WhatIsCyML'">What is CyML ?</b-dropdown-item>
          <b-dropdown-item :to="'/Crop2ML/Tools'">Crop2ML tools</b-dropdown-item>
          <b-dropdown-item :to="'/Crop2ML/Platforms'">Supported modeling platforms</b-dropdown-item>
        </b-nav-item-dropdown>
        <b-nav-item-dropdown text="AMEI" >
          <b-dropdown-item :to="'/AMEI/Vision'">Vision and motivations</b-dropdown-item>
          <b-dropdown-item :to="'/AMEI/Supports'">Supports</b-dropdown-item>
          <b-dropdown-item :to="'/AMEI/Members'">Core members</b-dropdown-item>
          <b-dropdown-item :to="'/AMEI/Events'">Events</b-dropdown-item>
        </b-nav-item-dropdown>
        <b-nav-item-dropdown text="About" >
          <b-dropdown-item :to="'/About/FAQ'">Submission FAQs</b-dropdown-item>
          <b-dropdown-item :to="'/About/VersionControl'">Version control</b-dropdown-item>
          <b-dropdown-item :to="'/About/Licenses'">Licenses</b-dropdown-item>
          <b-dropdown-item :to="'/About/HowToCite'">How to cite</b-dropdown-item>
          <b-dropdown-item :to="'/About/TermsOfUse'">Terms of use</b-dropdown-item>
          <b-dropdown-item :to="'/About/ContactUs'">Contact us</b-dropdown-item>
        </b-nav-item-dropdown>

      </b-navbar-nav>

      <!-- Right aligned nav items -->
      <b-navbar-nav class="ml-auto">
        <b-nav-item-dropdown right>
          <!-- Using 'button-content' slot -->
          <template #button-content>
            <em v-if="!isLogged">User</em>
            <em v-else>{{user.userName}}</em>
          </template>

          <b-dropdown-item v-if="!isLogged" :to="'/Register'">Register</b-dropdown-item>
          <b-dropdown-item v-if="!isLogged" :to="'/SignIn'">Sign in</b-dropdown-item>

          <b-dropdown-item v-if="isLogged" :to="{name: 'Profile', params: { username : user.userName }}">My Profile</b-dropdown-item>
          <b-dropdown-item v-if="isLogged" :to="'/EditProfile'">Edit profile</b-dropdown-item>
          <b-dropdown-item v-if="isLogged" :to="'/SignOut'">Sign out</b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </b-collapse>

  </b-navbar>

</template>

<script>

export default {
  name: "NavBar",
  components: {},
  
  computed: {
    user() {
      return this.$store.state.user
    },
    isLogged(){
      return this.user !== null
    }
  },
  methods: {
  },
};
</script>

<style scoped>

</style>
