<template>
  <div v-if="isDataLoaded" class="container mt-4">
    <b-row>
      <!-- Colonne de gauche -->
      <div class="col-md-9">
        <b-row align-v="end">
          <b-img fluid 
          style="max-width:250px;margin-right:20px;" 
          alt="User photo"
          :src="getPicturePath(community.picture)"/>
          <h5 style="font-size:2.5rem;">{{ community.name }}</h5>
          
          <b-button 
            v-if="isStarred && !isAdmin"
            variant="warning" 
            style="position:absolute; right: 50px;"
            @click="removeStar">
            <b-icon icon="star-fill"></b-icon> starred
          </b-button>
          <b-button 
            v-if="!isStarred && !isAdmin"
            variant="primary" 
            style="position:absolute; right: 50px;"
            v-b-modal="'star-modal'"
            @click="addStar">
            <b-icon icon="star"></b-icon> star
          </b-button>
          <b-button 
            v-if="isAdmin" 
            style="position:absolute; right: 110px;" 
            variant="primary" 
            :to="{ name: 'EditCommunity', params: {community:community.name} }">
              <b-icon icon="pen"></b-icon> Edit
          </b-button>
          <b-button 
            v-if="isAdmin" 
            style="position:absolute; right: 20px;" 
            variant="danger" 
            v-b-modal="'delete-modal'"
            @click="openDeleteModal=true">
              Delete
          </b-button>
          <b-modal id="delete-modal" title="Delete community ?" ok-title="Yes I'm sure" ok-variant="danger" @ok="deleteCommunity()">
            <p class="my-4">{{ `Are you sure you want to delete the community ?`}}</p>
          </b-modal>
        </b-row>
        <b-row class="mt-4">
          <b-card title="Description" border-variant="light" class="text-left">
            <b-card-text>{{ community.description }}</b-card-text>
          </b-card>
        </b-row>
        <b-row class="mt-4">
          <h4 style="padding-left:20px;"> Models ({{communityModels.length}})</h4>
        </b-row>
        <b-row v-if="communityModels.length>0" style="padding-left:20px;">
          <model-card 
            class="text-left mb-4"
            v-for="(model, key) of communityModels"
            :key="key"
            :model="model"
            :version="model.versions[model.versions.length -1]"
            :parent="'community'"
          />
          
        </b-row>
      </div>

      

      <!-- Colonne de droite -->
      <div class="col-md-3">
        <div class=" mb-4">
          <b-card sub-title="Creator" border-variant="light" >
            <b-card-text >
              <b-link :to="`/Profile/${community.createdByUsername}`">    
              {{community.createdByUsername}}
              </b-link>
            </b-card-text>
          </b-card>
          <b-card  border-variant="light">
            <b-card-sub-title>Administrators ({{community.administratorsUsernames.length}})</b-card-sub-title>
            <span v-for="(admin,index) of community.administratorsUsernames" :key="index">
              <b-link :to="`/Profile/${admin}`">{{ admin }}</b-link>
              <!-- Add a comma after each item except the last -->
              <span v-if="index < community.administratorsUsernames.length - 1">, </span>
            </span>
            
          </b-card>
          
          <b-card border-variant="light" v-if="community.starredByUsernames && community.starredByUsernames.length>0">
            <b-card-sub-title>Followers ({{community.starredByUsernames.length}})</b-card-sub-title>
            <p class="m-0" v-if="community.starredByUsernames.length < 10">
              {{ community.starredByUsernames.join(', ') }}
            </p>
            <p class="m-0" v-else>
              {{ community.starredByUsernames.slice(0, 9).join(', ') }}...
            </p>
          </b-card>
        </div>
      </div>
    </b-row>

    <b-modal 
      v-if="openLogModal" 
      id="star-modal" 
      title="Logged in users only"
      ok-only
        >
      <p class="my-4">You must log in or create an account to add the community to favorite</p>
    </b-modal>
    
  </div>
</template>

<script>
import ModelCard from '../models/ModelCard.vue';

export default {
  name: "CommunityDetails",
  components: {
    ModelCard
  },

  props: {
  },
  data() {
    return {
      communityName:null,
      openLogModal: false,
      openDeleteModal: false,
      defaultPicture:"images/community_iconfinder_128px.png" ,
    }
  },
  mounted(){
    this.communityName = this.$route.params.community;
    const getCommu = this.$store.getters.getCommunities.find(c=>c.name === this.communityName)
    if (typeof getCommu === 'undefined') {
          this.$router.push({name: 'PageNotFound'})
        } 
  },
  computed: {
    isDataLoaded() {
      return this.$store.getters.getIsDataLoaded
    },
    user(){
      return this.$store.getters.getUser
    },
    community(){
      return this.$store.getters.getCommunities.find(c=>c.name === this.communityName)
    },
    models(){
      return this.$store.getters.getModels
    },
    communityModels(){
      return this.community.modelPackages.map(mp => {
        return this.models.find(m => m.id === mp)
      }).filter(el => el !== undefined)
    },
    isStarred(){
      return this.community.starredBy && this.user && this.community.starredBy.includes(this.user.email)
    },
    isAdmin(){
      return this.user && this.community.administratorsUsernames.includes(this.user.userName)
    },
    //getPicturePath(){
    //  if (!this.community && this.community.picture) return this.defaultPicture
    //  return `${this.$baseUrl}/community_images/` + this.community.picture
    //},
    
  },
  methods: {
    getPicturePath(picture){
      return `${this.$baseUrl}/community_images/` + picture
    },
    
    async addStar(){
      if (this.user === null) {
        this.openLogModal = true
        return;
      }
      if (this.community.starredBy) {
        this.community.starredBy.push(this.user.email)
      } else {
        this.community.starredBy = [this.user.email]
      }
      this.$store.dispatch('updateCommunity',{communityid:this.community._id,payload:{starredBy:this.community.starredBy}})
    },
    async removeStar() {
      const index = this.community.starredBy.indexOf(this.user.email);
      if (index > -1) this.community.starredBy.splice(index, 1)
      this.$store.dispatch('updateCommunity',{communityid:this.community._id,payload:{starredBy:this.community.starredBy}})
    },
    async deleteCommunity() {
      this.$store.dispatch('deleteCommunity',this.community)
      this.$router.push("/Communities")
    }
  }
};
</script>

<style scoped>
.card {
  height: 100%;
}

p{
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 2px;
  padding-right: 2px;
}

.caption {
  font-size: smaller;
  color: grey;
  padding-top: 0px;
  text-align: left;
  margin-bottom: 0px;
}

</style>
