<template>
  <div>
    <p>Redirection to model page...</p>
  </div>
</template>

<script>
export default {
  name:'IdToNameRedirection',
  props: ['id'],
  async beforeRouteEnter(to, from, next) {
    next(async(vm) => {
      const id = parseInt(to.params.id);
      await vm.$store.dispatch('loadPublicModels')
      // Wait until models is populated
      vm.$watch(
        () => vm.models,
        (newVal) => {

          if (newVal && newVal.length > 0) {
            const model = newVal.find(item => item.identifiersLUI === id);
            console.log('model',model)
            if (model && model.id) {
              next({ path: `/Model/${model.id}` });
            } else {
              next('/PageNotFound');
            }            
          } else {
              next('/PageNotFound');
            }
        },
        { immediate: true }
      );
    })
  },
  
  computed:{
    models(){
        return this.$store.getters.getModels
      },
  },
}
</script>
