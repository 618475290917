import axios from 'axios';
import config from '../config'

var url = `${config.server.protocol}://${config.server.host}:${config.server.port}/server/`;


class UserServices{

    static loadUserByEmail = async (email) =>{
        const res = await axios.get(url + "user/getUserByEmail",{params:{email:email},
            headers: {
                'Authorization': `${sessionStorage.getItem("token")}`
            }
        })
        return res.data
        
    }

    static loadUserByUserName = async(userName) =>{
        const res = await axios.get(url + "user/getUserByUserName",{params:{userName:userName}}) 
        return res.data
    }

    
}


export default UserServices