<template>

  <div id="Communities" v-if="isDataLoaded" style="display:block; overflow: scroll; height:80vh; width:100%; margin:20px;" >

    
    <div id="Communities"> 
      <h2> Communities created by Crop2ML users</h2>

      <div style="float:center; " >
        <b-button variant="outline-success" :to="'/NewCommunity'">
          New community
        </b-button>
      </div>
      
      <br>

      <b-container>
        <b-row>
          <b-col 
            v-for="(community, index) of communities"
            :key="index" 
            cols="12" md="4"
            style="padding-bottom: 20px;"
          >
            <community-card 
                  class="text-center"
                  
                  :community="community"
                  :parent="'communities'"
                />
            
          </b-col> 
        </b-row> 
      </b-container>

      <div v-if="communities.length==0 && !isDataLoaded">
        <b-spinner variant="success" label="Spinning"></b-spinner>
      </div>


      <div v-if="communities.length==0 && isDataLoaded">
        <h4>
           No community has been created yet
        </h4>
      </div>

    </div>



</div>
  
</template>
<script>
import CommunityCard from '../community/CommunityCard.vue';

export default {
  name: 'Communities',
  components: {
    CommunityCard
  },
  data() {
    return {
    }
  },

  computed:{
    isDataLoaded() {
      return this.$store.getters.getIsDataLoaded
    },
    communities() {
      // sort by name (even if not returned ¯\_(ツ)_/¯ )
      const origCommu = this.$store.getters.getCommunities

      origCommu.sort((a,b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : ((b.name.toLowerCase() > a.name.toLowerCase()) ? -1 : 0))
      return origCommu
    },
    user() {
      return this.$store.getters.getUser
    },
  },

  methods: {
  },

  watch:{ 
  },

}
</script>

<style scoped>





</style>
