<template>
    <b-card style="margin-top: 1em;"
        header-bg-variant="secondary"
        header-text-variant="white"
        class="text-left"
        @click="$router.push({name:'Model', params:{ modelid : model.id }})"
    >
      <template #header>
        {{model.id}}
        <b-badge v-if="!isPublic && parent==='modelDetails'" variant="warning" class="px-3 py-2" style="position:absolute; right: 20px;font-size: 0.9rem;">
          Private model
        </b-badge>
        
        <div v-if="isPublic" style="position:absolute; right: 20px;top:10px">
        <b-icon icon="eye" ></b-icon> {{model.views || 0}}
        <b-icon icon="download"></b-icon> {{model.downloads || 0}}
        </div>
      </template>
      
      <b-row no-gutters>
          <b-col lg="3" >
              <b-card-img v-if="parent==='profile'" :src="getPicturePath('packages', model.picture)" style="max-width:100px" alt="Model Preview" ></b-card-img>
              <b-card-img v-else :src="getPicturePath(null,model.picture)" style="max-width:100px" alt="Model Preview" ></b-card-img>
          </b-col>
          <b-col lg="9" class="text-left">
              <h4> {{ version.Attributs.name }}</h4>
              <p>
                {{ version.Description.ShortDescription }}
              </p>
              <p v-if="parent==='repository'" class="caption"> Submitter : {{version.AddedMetadata.uploaderUsername}} | Model version : {{version.Metadata.Attributs.version}} | Uploaded on : {{ formatDate(model.createdAt) }} | Last modified on : {{ formatDate(model.updatedAt) }}</p>

              <div v-if="parent==='profile'">
              <b-badge v-if="model.administratorsUsernames.includes(userProfile.userName)" variant="info" style="margin-right:3px;">
                Aministrator
              </b-badge>
              <b-badge v-if="model.editorsUsernames.includes(userProfile.userName)" variant="info" style="margin-right:3px;">
                Editor
              </b-badge>
              <b-badge v-if="!model.isPublic" variant="warning" style="margin-right:3px;">
                Private
              </b-badge>
              </div>
          </b-col>


      </b-row>

      <b-row no-gutters v-if="parent==='modelDetails'">
        <div style="margin-right: 12px;" class="text-center" v-if="!isUnitModel">
            <b-button variant="primary" @click="$event.stopPropagation();downloadModel()">Download model</b-button>
        </div >
        <div style="margin-right: 12px;" class="text-center" v-if="isStarred && !canEdit">
          <b-button 
              variant="warning" 
              style="position:absolute; right: 50px;"
              @click="$event.stopPropagation();removeStar()">
              <b-icon icon="star-fill"></b-icon> starred
            </b-button>
        </div >
        <div style="margin-right: 12px;" class="text-center" v-if="!isStarred && !canEdit">
          <b-button 
            variant="primary" 
            style="position:absolute; right: 50px;"
            v-b-modal="'star-modal'"
            @click="$event.stopPropagation();addStar()">
            <b-icon icon="star"></b-icon> star
          </b-button>
          <b-modal v-if="openLogModal" id="star-modal" title="Logged in users only" ok-only >
            <p class="my-4">You must log in or create an account to add the community to favorite</p>
          </b-modal>
        </div>
        <!--div class="col-md-4">
            <div @mouseleave="showCurrentRating(0)" style="display:inline-block;">
                <star-rating :show-rating="false" @current-rating="showCurrentRating" @rating-selected="setCurrentSelectedRating" :increment="0.5"></star-rating>
            </div>
            <div style="margin-top:10px;font-weight:bold;">{{currentRating}}</div>
        </div -->
        
        <div style="margin-right: 12px;" class="text-center" v-if="isAdmin">
            <b-button variant="primary" @click="$event.stopPropagation();$router.push('/Submit')">Upload new version</b-button>
        </div>
        <div style="margin-right: 12px;" class="text-center" v-if="canEdit">
            <b-button variant="primary" @click="$event.stopPropagation();$router.push({name: 'Edit', params: { modelid : model.id }})">Edit metadata</b-button>
        </div>
        <div style="margin-right: 12px;" class="text-center" v-if="isAdmin && !isPublic">
            <b-button variant="warning" @click="$event.stopPropagation();$bvModal.show('modal-publish')">Make Public</b-button>
            <b-modal id="modal-publish" title="Publish model" ok-title="Yes I'm sure" ok-variant="warning" @ok="publishModel()">
              <p class="my-4">{{ `Are you sure you want to make the model ${version.Attributs.name} publicly available to the catalog ?`}}</p>
            </b-modal>
        </div>
        <div style="margin-right: 12px;" class="text-center" v-if="isAdmin">
            <b-button variant="danger" @click="$event.stopPropagation();$bvModal.show('modal-delete')">Delete model</b-button>
            <b-modal id="modal-delete" title="Delete model ?" ok-title="Yes I'm sure" ok-variant="danger" @ok="deleteModel()">
              <p class="my-4">{{ `Are you sure you want to delete the version ${version.Attributs.version} of the model ${version.Attributs.name} ?`}}</p>
            </b-modal>
        </div>
        
      </b-row>

    </b-card>
</template>
<script>
import FileServices from "../../services/FileServices"


export default {
  name: 'ModelCard',
  props: {
    model: {
      type: Object,
      default: null,
    },
    version: {
      type: Object,
      default: null,
    },
    parent: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      isPublic:false,
      isUnitModel:false,
      openLogModal: false,
    }
  },
  created() {
    this.isPublic = this.model.isPublic
  },

  mounted() {
  },

  computed:{
    user(){
      return this.$store.getters.getUser
    },
    userProfile(){
      return this.$store.getters.getUserProfile
    },
    isLogged(){
      return this.user !== null
    },
    isAdmin(){      
      return this.isLogged && !this.isUnitModel && this.model.administrators.includes(this.user.email)
      //this.$store.getters.getUser.associatedModels.findIndex(m => (m.modelId == this.model.id && m.role == "administrator")) != -1)
    },
    isEditor(){
      return this.isLogged && !this.isUnitModel && this.model.editorMails && this.model.editorMails.includes(this.user.email)
      //this.$store.getters.getUser.associatedModels.findIndex(m => (m.modelId == this.model.id && m.role == "editor")) != -1)
    },
    canEdit(){      
      return this.isAdmin || this.isEditor
    },
    isStarred(){
      return this.model.starredBy && this.user && this.model.starredBy.includes(this.user.email)
    },
  },
  methods: {
    getPicturePath(type,picture){
      if (!picture) return this.defaultPicture
      if (type) return `${this.$baseUrl}/${type}_images/` + picture
      else return `${this.$baseUrl}/packages_images/` + picture
    },
    formatDate(dateString) {
        const date = new Date(dateString);
            // Then specify how you want your dates to be formatted
        return new Intl.DateTimeFormat('default', {dateStyle: 'medium'}).format(date);
    },
    async deleteModel(){
      this.errorMsg =""
      const res = await this.$store.dispatch('deleteModel', {modelid: this.model.id, version: this.version.Metadata.Attributs.version, user: this.user.email});
      this.$emit('delete-model', res)
      //this.version = null
      //this.selectModelById()
      //if (typeof this.model === 'undefined'){
      //  this.$router.go(-1)
      //}
    },

    async publishModel() {
      await this.$store.dispatch('updateModel', {modelid:this.model.id, payload:{isPublic:true}})
      this.isPublic = true
    },

    async addStar(){
      if (this.user === null) {
        this.openLogModal = true
        return;
      }
      if (this.model.starredBy) {
        this.model.starredBy.push(this.user.email)
      } else {
        this.model.starredBy = [this.user.email]
      }
      await this.$store.dispatch('updateModel',{modelid:this.model.id, payload:{starredBy:this.model.starredBy}})
    },

    async removeStar() {
      const index = this.model.starredBy.indexOf(this.user.email);
      if (index > -1) this.model.starredBy.splice(index, 1)
      await this.$store.dispatch('updateModel',{modelid:this.model.id, payload:{starredBy:this.model.starredBy}})
    },

    async downloadModel(){
      const res =  await FileServices.downloadZip(this.version.AddedMetadata.zipName)
      await this.$store.dispatch('updateModel', {modelid:this.model.id, payload:{downloads:1}})
      console.log('downloaded model',res)
    },
  },

  watch:{
  },

}
</script>
<style scoped>

@import "https://cdn.jsdelivr.net/npm/@voerro/vue-tagsinput@2.7.1/dist/style.css";

.caption {
  font-size: smaller;
  color: grey;
  padding-top: 0px;
  text-align: left;
  margin-bottom: 0px;
}

</style>