import Vue from "vue";
import Vuex from "vuex";

import ModelServices from '../services/ModelServices.js'
import CommunityServices from '../services/CommunityServices.js'
import UserServices from '../services/UserServices.js'
import AuthServices from '../services/AuthServices.js'
import VueJwtDecode from "vue-jwt-decode";

Vue.use(Vuex);


export default new Vuex.Store({

   state: {
     models : [],
     communities: [],
     isDataLoaded:false,
     currentNavPath: null,
     user: null,
     userProfile:null,
     keywordsObj: {},
     catalogFilters: [],
     token: null,
     //error:{},
   },

   actions: {
      // ERROR
      showError(_, message) {
         Vue.notify({
           title: 'Error',
           text:  message,
           type: 'error',
         });
      },
      // VALID
      showSuccess(_, message) {
         Vue.notify({
           title: 'SAVED',
           text:  message,
           type: 'success',
         });
      },

      // AUTH
      async signIn({commit, dispatch}, credentials) {
         try {
            const res = await AuthServices.signIn(credentials)
            commit('setUser', res.data.user)
            commit('setToken', res.data.token)
            dispatch('loadUserPrivateModels');
         } catch (err) {
            dispatch('showError',err.message); 
         }
      },

      async updateProfile({state,dispatch}, {image, userProfileDetails}) {
         try {
            await AuthServices.updateProfile(image, userProfileDetails)
            dispatch('loadUserByEmail')
            dispatch('loadUserProfile', state.user.userName)
         } catch (err) {
            dispatch('showError',err.message); 
         }
      },
      
      // USER
      async loadUserByEmail({commit,dispatch}) {
         // get token from sessionStorage
         let token = sessionStorage.getItem("token");
         try { 
            let decoded = VueJwtDecode.decode(token);
            const res = await UserServices.loadUserByEmail(decoded.email)
            commit('setUser', res.user)
            commit('setToken', res.token)
         } catch (err) {
            dispatch('showError',err.message); 
         }
      },

      async loadUserProfile({commit,dispatch}, userName) {
         try { 
            const user = await UserServices.loadUserByUserName(userName)
            commit('setUserProfile', user)
         } catch (err) {
            dispatch('showError',err.message); 
         }
      },

      // MODELS
      async loadPublicModels({commit,dispatch }) {
         try {
            const models = await ModelServices.loadPublicModels()
            for(const model of models){
               commit('setModel', model)
            }
         } catch (err) { 
            dispatch('showError',err.message);            
         }         
      },

      async loadUserPrivateModels({ commit,dispatch }) {
         try {
            const models = await ModelServices.loadUserPrivateModels()
            if (Array.isArray(models)) { 
               for(const model of models){
                  commit('setModel', model)
               }
            }
         } catch (err) { 
            dispatch('showError',err.message);            
         }         
      },

      async loadPrivateModelById({ commit ,dispatch}, id) {
         try {
            const model = await ModelServices.loadPrivateModelById(id)
            if (model) commit('setModel', model)
         } catch (err) { 
            dispatch('showError', err);
            // return to show 404 page does not exist from router
            return err
         }         
      },

      async saveModel({commit,dispatch}, data){
         try {
            const res = await ModelServices.saveModel(data.model, data.isUploaded, data.image)
            if (!res) throw new Error('Error while saving the model')
            if (data.isUploaded) {
               commit("setModel", res.data.model);
            } else {
               commit("updateModel", res.data.model);
            }
            if (data.model.linkedCommunities) {
               dispatch('loadAllCommunities')
            }
            return res.data.success;
         } catch (err) { 
            console.error(err)
            dispatch('showError',err.message);         
         } 
      },

      async updateModel({commit,dispatch}, newData){
         try {
            const res = await ModelServices.updateModel(newData.modelid, newData.payload)
            if (res.success) commit("updateModel", res.model);
            //return res.sucess;
         } catch (err) { 
            console.error(err)
            dispatch('showError',err.message);         
         } 
      },

      async deleteModel({commit,dispatch}, modelData){
         try {
            const res = await ModelServices.deleteModelById(modelData.modelid, modelData.version, modelData.user)
            let success = res.success
            let updatedModel = res.model
            if(success){
               if (updatedModel == "" ){
                  commit('deleteModel',modelData.modelid)
               } else {
                  commit('setModel',updatedModel)
               }
            } 
            return success;
         } catch (err) { 
            console.error(err)
            dispatch('showError',err.message);         
         } 
      },

      // COMMUNITIES

      async loadAllCommunities({ commit ,dispatch}) {
         try {
            const communities = await CommunityServices.loadAllCommunities()
            commit('setCommunities', communities)
         } catch (err) { 
            dispatch('showError',err.message);            
         }         
      },

      async createOrUpdateCommunity({ commit ,dispatch},{image, community}) {
         try {
            const res = await CommunityServices.createOrUpdateCommunity(image,community)
            if (!res) return;
            commit('setCommunity', res.data.value)
            return res.data
         } catch (err) { 
            dispatch('showError',err.message); 
            return err           
         }         
      },

      async updateCommunity({commit,dispatch}, newData){
         try {
            const res = await CommunityServices.updateCommunity(newData.communityid, newData.payload)
            if (res.success) commit("setCommunity", res.community);
            //return res.sucess;
         } catch (err) { 
            console.error(err)
            dispatch('showError',err.message);         
         } 
      },

      async deleteCommunity({dispatch }, community) {
         try {
            await CommunityServices.deleteCommunityById(community._id)
            dispatch('loadAllCommunities')
         } catch (err) { 
            dispatch('showError',err.message);            
         }         
      },
   },

   mutations: {
      setModels: (state, models) => {
         state.models = models
      },
      
      setModel: (state, model) => {
         state.models = [...state.models, model]

         for(let k of model.versions[0].AddedMetadata.keywords){
            if(!(Object.prototype.hasOwnProperty.call(state.keywordsObj,k))){
               state.keywordsObj[k]=[]
            }
            state.keywordsObj[k].push(model.id)
         }
      },

      updateModel(state, newModel) {
         const index = state.models.findIndex(m => m.id === newModel.id);
         if (index !== -1) {
           state.models.splice(index, 1, newModel);
         } else { console.log('MODEL INDEX NOT FOUND', index)}
      },

      deleteModel: (state, modelid) => {
         const index = state.models.findIndex(m => m.id === modelid);
         if (index !== -1) {
           state.models.splice(index, 1);
         }
      },

      setCommunity: (state, newCommunity) => {
         const index = state.communities.findIndex(c => c._id === newCommunity._id)
         const oldCommunity = state.communities[index]
         // update
         if (oldCommunity) {
            Vue.set(state.communities, index, { ...oldCommunity, ...newCommunity });
         } else { // add
            state.communities = [...state.communities, newCommunity]
         }
         
      },
      setCommunities: (state, comm) => {
         state.communities = comm
      },

      setIsDataLoaded: (state, bool) =>{
         state.isDataLoaded = bool
      },
      
      setCurrentNavPath: (state,val) =>{
         state.currentNavPath = val;
      },

      setUser:(state,val) =>{
         state.user = val
      },

      setToken(state, token) {
         //state.token = token;
         sessionStorage.setItem("token", token);
      },

      setUserProfile:(state,val) =>{
         state.userProfile = val
      },
      setCatalogFilters: (state, tags) => {
         state.catalogFilters = tags
      }
   },

   getters: { 

      getModels: (state) => {
         return state.models;
      },

      getModelById: (state) => (id) => {
         return state.models.find(m=>m.id === id);
      },

      getModelByIdAndVersion: (state) => (mainModelId, version, unitModelId) => {
         let isUnitModel = true
         let mainModel = state.models.find(m=>m.id === mainModelId);
         let compoModel = null
         let unitModel = null
         if (typeof mainModel !== 'undefined'){
            if (version == null) {
               version = mainModel.versionsList.sort()[mainModel.versionsList.length -1]
            }
            compoModel = mainModel.versions.find(m => m.Metadata.Attributs.version == version)
            if (unitModelId == null){
               isUnitModel = false
            } else {
               unitModel = compoModel.Composition.Model.find(m => m.Attributs.id == unitModelId).ModelContent
            }
         }
 
         return [isUnitModel, mainModel, compoModel, unitModel, version]
      },

      getCommunities: (state) => {
         return state.communities;
      },

      getCommunityByName: (state) => (communityName) => {
         return state.communities.find(c => c.name === communityName);
      },

      getIsDataLoaded:(state) =>{
         return state.isDataLoaded
      },

      getLoggedUserEMail:(state) =>{
         if (state.user == null)
            return null
         else
            return state.user.email
      },
      
      getUser:(state) =>{
         return state.user
      },

      getUserProfile:(state) =>{
         return state.userProfile
      },

      getToken:() =>{
         return sessionStorage.getItem("token")
         //return state.token
      },

      /*isLoggedIn(state) {
         return !!state.token;
       },*/

      getKeywords:(state) =>{
         let allWords = Array.prototype.concat([],Object.keys(state.keywordsObj))
         allWords.sort()
         let allWordsObjects = []
         for (const w of allWords) {
            allWordsObjects.push({"value":w})
         }
         return allWordsObjects
      },

      getCatalogFilters:(state) =>{
         return state.catalogFilters
      },

   },

   

   
});

