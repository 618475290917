import axios from 'axios';
import config from '../config'

var url = `${config.server.protocol}://${config.server.host}:${config.server.port}/server/`;

class AuthServices {
    
    static register(file,body) {
        const formData = new FormData();
        formData.append("picture", file);
        formData.append("body", JSON.stringify(body));
        return new Promise((resolve, reject) => {
            try {
                axios.post(url + "auth/register", formData).then(res => {
                    resolve(res.data);
                })
            } catch (err) { reject(err); }
        })
    }

    static validateRegistration(body) {
        return new Promise((resolve, reject) => {
            try {
                axios.post(url + "auth/validateRegistration", body).then(res => {
                    resolve(res.data);
                })
            } catch (err) { reject(err); }
        })
    }

    static updateProfile = async (file, body) =>{
        const formData = new FormData();
        formData.append("picture", file);
        formData.append("body", JSON.stringify(body));
        return await axios.post(url+'auth/updateProfile', formData, {
            // headers: formData.getHeaders()
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `${sessionStorage.getItem("token")}`
            }
        })
    }

    static async signIn(credentials) {
        return await axios.post(url + "auth/signIn", credentials)
    }

    static forgotPassword(email) {
        return new Promise((resolve, reject) => {
            try {
                let body = { email}
                axios.post(url + "auth/forgotPassword", body).then(res => {
                    resolve(res.data);
                })

            } catch (err) { reject(err); }
        })
    }

    static resetPassword(body) {
        
        return new Promise((resolve, reject) => {
            try {
                axios.post(url + "auth/resetPassword", body).then(res => {
                    resolve(res.data);
                })

            } catch (err) { reject(err); }
        })
    }

    static contactUs(body) {
        return new Promise((resolve, reject) => {
            try {
                axios.post(url + "auth/contactUs", body).then(res => {
                    resolve(res.data);
                })
            } catch (err) { reject(err); }
        })
    }

    static contactUser(body) {
        return new Promise((resolve, reject) => {
            try {
                axios.post(url + "auth/contactUser", body).then(res => {
                    resolve(res.data);
                })
            } catch (err) { reject(err); }
        })
    }

}

export default AuthServices;