<template>
  <div class="container">
    <b-row>
      <b-col>
        <h1 class="heading">
          Legal Notices
        </h1>

        <p style="text-align: justify;">
          The CropMRepository website https://crop2ml.org is a web application developed by the Agricultural Model
          Exchange Initiative. Funding for its publication and development comes from the Digital Agriculture
          Convergence Laboratory (<b-link
            target="_blank"
            href="https://www.hdigitag.fr/en/"
            class="text-decoration-none">#DigitAg</b-link>). As such, it benefited from French State funding managed by the
          National
          Research Agency under the program "Investissements d'Avenir" with the reference ANR-16-CONV-0004.
        </p>
        <p style="text-align: justify;">
          The logos and other content on this site are subject to intellectual property rights. Consequently, it is
          forbidden to reuse them without prior authorization.
        </p>
        <p style="text-align: justify;">
          The contents of public nature are usable under the conditions of the MIT open license.
        </p>
      </b-col>
    </b-row>
    <b-row class="text-left" style="margin-top: 20px">
      <b-col>
        <h3>
          Editor of the site
        </h3>
        <p style="text-align: justify;">
          The editor of the site is INRAE, a public establishment of a scientific and technological nature. INRAE
          headquarter is located 147 rue de l'Université, 75338 Paris cedex 07, France.
        </p>
        <p style="text-align: justify;">
          The application is hosted on a server owned and managed by INRAE.
        </p>
      </b-col>
    </b-row>
    <b-row class="text-left" style="margin-top: 20px">
      <b-col>
        <h3>
          Site designer
        </h3>
        <p style="text-align: justify;">
          The current version of https://crop2ml.org was developed by
          <b-link
              target="_blank"
              href="https://eneo.fr/"
              class="text-decoration-none">ENEO</b-link>.
        </p>
      </b-col>
    </b-row>
    <b-row class="text-left" style="margin-top: 20px">
      <b-col>
        <h3>
          INRAE responsibility
        </h3>
        <b-list-group flush>
          <b-list-group-item class=" flex-column align-items-start py-4 px-4">
            <b-list-group>
              <b-list-group-item class="flex-column align-items-start">
                <div class="d-flex w-100 justify-content-between">
                  <div>
                    <div class="d-flex w-100 align-items-start">
                      <h5 class="mb-1">Availability of the site:</h5>
                    </div>
                  </div>
                </div>
                <p class="text-left mb-1">
                  Except for legal provisions to the contrary, INRAE does not guarantee the availability of the site or
                  the permanence of the information on the site over time.
                </p>
              </b-list-group-item>

              <b-list-group-item class="flex-column align-items-start">
                <div class="d-flex w-100 justify-content-between">
                  <div>
                    <div class="d-flex w-100 align-items-start">
                      <h5 class="mb-1">Computer security:</h5>
                    </div>
                  </div>
                </div>
                <p class="text-left mb-1">
                  Except as otherwise provided by law, the site, its features and content are not guaranteed to be free
                  from hacking, bugs, viruses or other malicious software that may affect your computer equipment,
                  software or data. Your use of the site and its features and content is at your own risk and under your
                  sole responsibility. You are required to take all necessary preventive measures to protect your own
                  data, software and/or computer systems against the contamination of any virus and against any attack.
                </p>
              </b-list-group-item>
            </b-list-group>
          </b-list-group-item>
        </b-list-group>
      </b-col>
    </b-row>
    <b-row class="text-left" style="margin-top: 20px">
      <b-col>
        <h3>
          Responsibility of the users
        </h3>
        <b-list-group flush>
          <b-list-group-item class=" flex-column align-items-start py-4 px-4">
            <b-list-group>
              <b-list-group-item class="flex-column align-items-start">
                <div class="d-flex w-100 justify-content-between">
                  <div>
                    <div class="d-flex w-100 align-items-start">
                      <h5 class="mb-1">Quality of the contents:</h5>
                    </div>
                  </div>
                </div>
                <p class="text-left mb-1">
                  Although INRAE strives to provide reliable content, errors, inaccuracies or omissions cannot be
                  completely excluded. You are solely responsible for the consultation, selection, use and
                  interpretation of the content of this site. INRAE shall not be liable to you or any third party for
                  any direct or indirect damages arising out of the use of the information, including but not limited
                  to, any inaccurate or incomplete information, or any error in the indexing of all or part of the site.
                </p>
              </b-list-group-item>
            </b-list-group>
          </b-list-group-item>
        </b-list-group>
      </b-col>
    </b-row>
    <b-row class="text-left" style="margin-top: 20px">
      <b-col>
        <h3>
          Personal data
        </h3>
        <p style="text-align: justify;">
          More details of the personal data we collect from you when you browse the website and how we process this
          personal data are detailed in our <b-link :to="'/PrivacyNotice'">Privacy Notice</b-link>.
        </p>
      </b-col>
    </b-row>

  </div>
</template>

<script>
export default {

  name: "LegalMentions",


  created() {

  },

  mounted() {

  },

  methods: {},
};
</script>
<style scoped>
.global {
  padding: 5px;
  width: auto;
  height: auto;
}

.title {
  grid-area: tl;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

p {
  text-align: justify;
  padding-left: 40px;
  padding-right: 40px;
}

ul {
  text-align: justify;
  padding-left: 40px;
  padding-right: 40px;
}


.nav-link {
  text-align: center;
}

</style>
