<template>
  
  <div v-if="isDataLoaded" style="display:block; width:100%; margin-top:20px; margin-bottom:100px" >
    
    <div class="row">  

      <div class="col-lg-2">
        <b-button :to="'/Repository'" style="margin-bottom: 20px;">Back to catalog</b-button>
      </div>

      <div class="col-lg-8">
        <div v-if="!canEdit">
            You are not allowed to edit this model.
        </div>

        <div v-else class="row" >
          <div class="col-sm-12">

            <div v-if="submitMode">
              
              <p style="text-align: justify; margin-bottom: 50px;">
              Any simulation model describing a soil or crop growth biophysical process can be submitted to CropMRepository if it has been published in a peer-reviewed scientific journal or posted in a preprint server (such as <b-link href="https://www.biorxiv.org/">BioRxiv</b-link>) prior to, or concurrently with, submission to a journal . Authors are encouraged to submit their models before publication of the associated paper. That way they can receive a unique and perennial model identifier that they can use in the publication as a reference, but the model will only be publicly available on CropMRepository once the paper is published, unless the authors would like to make their model available to the scientific communauty ahead of its publication.
              <br><br>
              Models must be submitted in the <b-link href="https://crop2ml.readthedocs.io/en/latest/" target="_blank">Crop2ML</b-link> format. A single zipped file should be submitted, whose structure must follow the Crop2ML package scheme. Submitters are strongly encouraged to use CropMStudio to validate their model package before submitting it to CropMRepository to ensure its syntactic and semantic correctness and that all unit tests are passed in the different programming languages.
              <br><br>
              Enter all relevant information in the appropriate fields in the online submission form to ensure correct indexing and archiving of your model. Note that any updates or changes made after the initial publication of a model on CropMRepository are maintained as revisions and will be assigned a new model identifier.
              <br><br>
              Once a model has been deposited on CropMRepository, the submitter will receive an email notification with a unique, stable and perennial submission identifier. This identifier can be used to reference the model in publications. Once the model is publicly available on CropMRepository, users can directly access the model using this identifier.
              <br><br>
              All models in CropMRepository are available under the terms of an open license. Therefore you need to agree to release the encoded model in the Public Domain before submitting it to CropMRepository.
              <br><br>
              Please <b-link to="/About/ContactUs">contact us</b-link> if you have any queries regarding depositing your models to CropMRepository.
              </p>

              <b> Submit a new model or update the version of a model.</b>

              <!--  Choose Package.zip -->
              <b-input-group  class="mt-3" >
                <b-form-file
                  v-model="packageZip"
                  :state="Boolean(packageZip) && Boolean(packageSizeOK)"
                  placeholder="Choose a file.zip or drop it here..."
                  drop-placeholder="Drop file.zip here..."
                  accept=".zip"
                ></b-form-file>
              </b-input-group>

              <div class="mt-3">
                {{ uploadMsg  }}
              </div>

              <b-button :disabled="!packageZip || !packageSizeOK" variant="primary" style="margin-top: 1em;" v-on:click="uploadZip()">Upload zip</b-button>

              <div v-if="submitted && !treeDataReceived">
                <p>
                  <b>Extracting archive...</b> 
                </p>
                <p>
                  <b-spinner small label="Small Spinner" type="grow"></b-spinner>
                  <b-spinner small label="Small Spinner" type="grow"></b-spinner>
                  <b-spinner small label="Small Spinner" type="grow"></b-spinner>
                </p>
              </div>

              <div v-if="submitted && !success">
                <p v-for="err in errors" :key="err" style="padding-top:1em;color:red;">
                  <b>{{ err }}</b>
                </p>
              </div>
            </div>

            <div v-if="(submitMode && success && treeDataReceived && !packageZip) || !submitMode">
              
              <p v-if="submitMode" style="text-align:left; padding-top:1em;">
                <b>Extracted package structure:</b>
                <b-tree-view v-on:nodeSelect="treeNodeSelect" :data="treeDataReceived"  :renameNodeOnDblClick=false :contextMenu=false :contextMenuItems=[] ></b-tree-view>
              </p>

              <h3 style="padding-top: 1em; text-align:left; "> {{ model.id }} metadata : </h3>
              <p style="margin-bottom: 0; text-align:left; font-weight: bold;">It is already prefilled with extracted data from the package or previous version of the model.</p>

              <div>

                <!-- Keywords -->
                <b-input-group style="padding-top: 1em;" prepend="Keywords">
                  <b-form-tags class="text-capitalize"
                    v-model="keywords"
                    separator=","
                    placeholder="Enter new keywords separated by comma"
                  ></b-form-tags>
                </b-input-group>
                <p class="caption">The keywords were extracted from the model package you have uploaded, here you can add other keywords to facilitate the indexing and make your model easily discoverable.</p>

                <!-- Linked Community -->
                <b-input-group prepend="Add model to a community ? (optional)" style="padding-top: 1em; text-align:left;">
                    <!-- Prop `add-on-change` is needed to enable adding tags vie the `change` event -->
                  <b-form-tags
                    v-model="model.linkedCommunities"
                    add-on-change
                    no-outer-focus
                  >
                    <template v-slot="{ tags, inputAttrs, inputHandlers, disabled, removeTag }">
                      <ul v-if="tags.length > 0" class="list-inline d-inline-block mb-2">
                        <li v-for="tag in tags" :key="tag" class="list-inline-item">
                          <b-form-tag
                            @remove="removeTag(tag)"
                            :title="tag"
                            :disabled="disabled"
                          >{{ tag }}</b-form-tag>
                        </li>
                      </ul>
                      <b-form-select
                        v-bind="inputAttrs"
                        v-on="inputHandlers"
                        :disabled="disabled || availableCommunityOptions.length === 0"
                        :options="availableCommunityOptions"
                      >
                        <template #first>
                          <!-- This is required to prevent bugs with Safari -->
                          <option disabled value="">Choose a community...</option>
                        </template>
                      </b-form-select>
                    </template>
                  </b-form-tags>
                </b-input-group>
                <p class="caption">You will be able to create a new community after submitting the model and associate it with that community by editing the model's metadata.</p>

                <!-- Image -->
                <b-input-group prepend="Image" style="padding-top: 1em; text-align:left;">
                  <b-form-file style="padding-top: 1em; text-align:left;"
                    id="inputImgFileForm"
                    v-model="inputImgFile"
                    :state="imageValidation"
                    placeholder="Select or drop img (.jpg, .png, .gif) here"
                    drop-placeholder="Drop an image (.jpg, .png, .gif) here ..."
                    accept=".jpg, .png"
                    @change="previewInputImgFile()"
                    >
                  </b-form-file>
                </b-input-group>
                <div v-if="fileErrorMsg">
                  <p style="color:red;">
                    {{fileErrorMsg}}
                  </p>
                </div>
                <p class="caption">Add an image file to promote instant public recognition. Accepted file formats are .png, .jpg/.jpeg. Prefered image size: 400 x 400 pixels. Max file size: 200 Kb.</p>
                
                <b-card-img
                  id="displayedImg"
                  :src="getPicturePath(model.picture)" 
                  style="max-width: 150px" 
                  alt="Model image"
                  top>
                </b-card-img>

                <!-- Model Package Administrators-->
                <b-input-group prepend="Administrators" style="padding-top: 1em; text-align:left;">
                  <b-form-tags class="form-control"  style="background: white;"
                      v-model="model.administrators"
                      :state="model.administrators.length>0"
                      separator=","
                      placeholder="Enter e-mail separated by comma"
                      invalid-tag-text="Please enter a valid email address"
                      :tag-validator="emailValidator"
                      duplicate-tag-text="duplicated e-mail"
                    ></b-form-tags>
                </b-input-group>
                <p class="caption">The administrators of a model can edit the metadata of the model entered in this form, submit a new version (revision) of the model, or delete the model from CropMRepository. By default, the submitter has the rights of an administrator, but you can also give these rights to other individuals. Note that an e-mail will be automatically sent to the entered e-mail addresses to notify the recipients of their role and invite them to create a user account on CropMRepository.</p>

                <!-- Model Package Editors -->
                <b-input-group prepend="Editors" style="padding-top: 1em; text-align:left;">
                <b-form-tags class="form-control"  style="background: white;"
                    v-model="model.editors"
                    separator=","
                    placeholder="Enter e-mail separated by comma"
                    invalid-tag-text="Please enter a valid email address"
                    :tag-validator="emailValidator"
                    duplicate-tag-text="duplicated e-mail"
                  ></b-form-tags>
                </b-input-group>
                <p class="caption">The editors of a model can edit the metadata of the model entered in this form, submit a new version (revision) of the model. Note that an e-mail will be automatically sent to the entered e-mail addresses to notify the recipients of their role and invite them to create a user account on CropMRepository.</p>
                
                <b-input-group prepend="Comments" style="padding-top: 1em; text-align:left;">
                  <b-form-textarea
                    v-model="comments"
                    placeholder="Add comments concerning the latest version of this package."
                    >
                  </b-form-textarea>
                </b-input-group>

              </div>

              <b-button v-if='!submitMode' variant="primary" style="margin-top: 1em;" v-on:click="saveModel()">Save metadata</b-button>
              <b-button v-if='submitMode' variant="primary" style="margin-top: 1em;" v-on:click="saveModel()">Submit model</b-button>
              <div class="mt-3">
                {{ editMsg }}
              </div>
              <b-button variant="secondary" style="margin-top: 1em;" @click="$router.go(-1)">Cancel</b-button>

              <b-modal id="modal-done" :title="this.editMsg" hide-footer>
                <p class="my-4">The model status is PRIVATE and is not yet visible to other users. <br> Make it PUBLIC to enable the model to anyone on Crop2ML.  </p>
                <p class="my-4">You will be redirected to the model page. </p>
                <b-button variant="primary" class="mt-3" block v-on:click="closeModal()">OK</b-button>
              </b-modal>
            </div>

          </div>
        </div>
      </div>
      
    </div>
  </div>

</template>
<script>

import FileServices from "./services/FileServices"
import { bTreeView } from 'bootstrap-vue-treeview'
const path = require('path');

export default {
  name: 'Submit',

   data() {
      return {
        regEmail: /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
        regLink: /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i,
        packageZip: null,
        packageZipSent : false,
        keywords:[],
        submitted: false,
        treeDataReceived :null,
        modelTypeSelected : 'Main',
        modelTypeOptions: [
            {value: 'Main', text: 'Main'},
            {value: 'Component', text: 'Component'},
        ],
        isPartOfLargerModel: false,
        success: false,
        errors: [],
        model: null,
        modelExists: false,
        submitMode: true,
        editMsg: null,
        fileErrorMsg: null,
        canEdit: true,
        inputImgFile: null,
        comments: null,
        packageSizeOK: false,
        packageZipMaxSize:15000000, // 15 Mb
        imageMaxSize:200000, // 200 kb
      }
    },

  computed:{
    isDataLoaded() {
      return this.$store.getters.getIsDataLoaded
    },
    uploadMsg: function(){
      let msg = ""
      if(!this.packageZip && !this.packageZipSent){
        msg= 'No selected file.zip'
      }
      if(!this.packageZip && this.packageZipSent){
        msg= 'Zip sent successfully'
      }
      if(this.packageZip && !this.packageSizeOK){
        msg= 'Must be a zip file less than 15Mo.'
      }
      return msg;
    },

    availableCommunityOptions() {
      let values = this.model.linkedCommunities || []
      return this.communityOptions.filter(opt => values.indexOf(opt) === -1)
    },
    user() {
      return this.$store.getters.getUser
    },
    isLogged(){
      return this.user !== null
    },
    isAdmin(){
      return this.isLogged && this.model.administrators.includes(this.user.email)
    },
    isEditor(){
      return this.isLogged && this.model.editors.includes(this.user.email)
    },
    communitiesAdministrated(){
      return this.$store.getters.getCommunities.filter(c => c.administratorsUsernames && c.administratorsUsernames.includes(this.user.userName))
    },
    communityOptions() {
      return this.communitiesAdministrated.map(c => c.name)
    },
    imageValidation(){
      return !this.inputImgFile || this.inputImgFile && this.inputImgFile.size < this.imageMaxSize
    },
  },

  components: {
    bTreeView
  },

  created() {
  },

  async mounted() {

    if (this.$route.name == "Edit" && this.$route.params.modelid != null){
      this.submitMode = false
      this.model = this.$store.getters.getModelById(this.$route.params.modelid)
      this.setAddedMetadata()
      if (!this.isAdmin && !this.isEditor){
        this.canEdit = false
      }
      
    }
  },
  methods: {

    treeNodeSelect(event){
      if(event.selected){
        console.log(event.data.name)
      }
    },
    async uploadZip(){

      if (!this.packageZip)
        return;

      const modelMetaDataPart ={
        uploaderMail: this.user.email,
      }

      this.submitted =true
      this.modelExists = false
      this.treeDataReceived = null
      this.keywords = []
      this.model = null,
      this.errors = [],
      this.editMsg = "" 

      const res = await FileServices.uploadZip(this.packageZip, modelMetaDataPart)
      this.errors = res.errors
      this.success = this.errors.length == 0 ? true : false
      this.treeDataReceived = [res.tree]
      this.model = res.model
      if (this.model.picture == null)
        this.model.picture = "modeling_iconfinder_128px.png"
      this.setAddedMetadata()

      if (!this.success){
        if (this.treeDataReceived)
          this.modelExists = true
      }
      
      this.packageZip = null
      this.packageZipSent = true;

    },

    async saveModel(){
      if (this.model.administrators.length == 0){
        this.editMsg = "At least one administrator is required."
        return
      }
      if(!this.imageValidation){
          this.editMsg = "image file too big"
          return
        }

      let image = this.inputImgFile;
      if (image != null){
        var extension = "." + image.name.split('.').pop();
        var name = path.basename(image.name, extension)
        this.model.picture = name + Date.now() + extension
      }
      
      this.editMsg = "" 
      let indexLatestVersion = this.getModelLatestVersionIndex()
      this.model.versions[indexLatestVersion].AddedMetadata.keywords = this.keywords
      this.model.versions[indexLatestVersion].AddedMetadata.comments = this.comments

      const modelPayload = {
        model: this.model, 
        isUploaded: this.submitMode, 
        image: image
      }
      let editSuccess = await this.$store.dispatch('saveModel',modelPayload);
      if (editSuccess) {
        if (this.submitMode) {
          this.editMsg = "Model submitted successfully"
          this.$bvModal.show("modal-done")
        } else {
          this.editMsg = "Metadata saved successfully"
          this.$store.dispatch('showSuccess',this.editMsg)
          this.$router.push({name: 'Model', params: { modelid : this.model.id }})
        }
        
      }
      else {
        this.editMsg = "Error while saving the model"
      }
    },

    getModelLatestVersionIndex(){
      let latestVersionNum = this.model.versionsList.sort()[this.model.versionsList.length -1]
      let indexLatestVersion = this.model.versions.findIndex(compo => compo.Metadata.Attributs.version == latestVersionNum)
      return indexLatestVersion
    },

    setAddedMetadata(){
      let indexLatestVersion = this.getModelLatestVersionIndex()
      this.keywords = this.model.versions[indexLatestVersion].AddedMetadata.keywords
      this.comments = this.model.versions[indexLatestVersion].AddedMetadata.comments
    },

    addLargerPackage(){
      this.largerModelPackageNames.push(this.selectedLargerPackage)
    },

    packageValidator(name){
      return this.registeredPackageNames.includes(name)
    },

    communityValidator(name){
      return this.communityNames.includes(name)
    },

    emailValidator(email){
      return (email == "")? "" : (this.regEmail.test(email)) ? true : false;
    },

    linkValidator(link){
      return (link == "")? "" : (this.regLink.test(link)) ? true : false;
    },

    previewInputImgFile() {
      var file = document.getElementById('inputImgFileForm').files[0];
      if (file.size > this.imageMaxSize) {
        this.fileErrorMsg = 'file too big, max size: '+this.imageMaxSize/1000+ ' kb'
        return;
      }
      var reader  = new FileReader();
      reader.onload = function(e)  {
          var image = document.getElementById("displayedImg");
          image.src = e.target.result;
        }
      reader.readAsDataURL(file);
     },

    getPicturePath(picture){
      return `${this.$baseUrl}/packages_images/` + picture
    },

    closeModal(){
      this.$bvModal.hide('modal-done')
      this.$router.push({name: 'Model', params: { modelid : this.model.id }})
    }
    
  },

  watch:{

    packageZip: function(){
      if(this.packageZip){
        this.packageZipSent=false;
        this.packageSizeOK = this.packageZip.size < this.packageZipMaxSize && this.packageZip.name.endsWith(".zip");
      }
    },
  }

}
</script>

<style scoped>

.packageZipInputGroup{
  width: 80%;
  text-align: center;
}

p{
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 2px;
  padding-right: 2px;
}

.scrollable-menu {
    height: auto;
    max-height: 60vh;
    overflow: scroll;
}

.caption {
  font-size: smaller;
  color: grey;
  padding-top: 0px;
  text-align: left;
  margin-bottom: 0px;
}

</style>
