import Vue from 'vue'
import Router from 'vue-router';

import axios from 'axios';
import config from '../config'
import store from '../store';

import Home from '../Home';
import Repository from '../Repository';
import Submit from '../Submit';
import IdToNameRedirection from '../IdToNameRedirection.vue';

import PageNotFound from '../components/others/PageNotFound';
import About_Contact from '../components/others/About_Contact';
import About_FAQ from '../components/others/About_FAQ';
import About_HowToCite from '../components/others/About_HowToCite';
import About_TermsOfUse from '../components/others/About_TermsOfUse';
import About_Licenses from '../components/others/About_Licenses';
import About_VersionControl from '../components/others/About_VersionControl';
import AMEI_Events from '../components/others/AMEI_Events';
import AMEI_Members from '../components/others/AMEI_Members';
import AMEI_Supports from '../components/others/AMEI_Supports';
import AMEI_Vision from '../components/others/AMEI_Vision';
import Crop2ML_Platforms from '../components/others/Crop2ML_Platforms';
import Crop2ML_Tools from '../components/others/Crop2ML_Tools';
import Crop2ML_WhatIsCrop2ML from '../components/others/Crop2ML_WhatIsCrop2ML';
import Crop2ML_WhatIsCyML from '../components/others/Crop2ML_WhatIsCyML';
import LegalMentions from '../components/others/LegalMentions';
import PrivacyNotice from '../components/others/PrivacyNotice';

import SignIn from '../components/auth/SignIn';
import SignOut from '../components/auth/SignOut';
import Register from '../components/auth/Register';
import ForgotPassword from '../components/auth/ForgotPassword';
import ResetPassword from '../components/auth/ResetPassword';

import Communities from '../components/community/Communities';
import CommunityDetails from '../components/community/CommunityDetails';
import EditCommunity from '../components/community/EditCommunity';

import ProfileDetails from '../components/profile/ProfileDetails';
import EditProfile from '../components/profile/EditProfile';

import ModelDetails from '../components/models/ModelDetails';

var url = `${config.server.protocol}://${config.server.host}:${config.server.port}/server/`;


Vue.use(Router)

const  routes = [
  {
    path: '/',
    redirect: '/Home'
  },
  
  {
    path: '/About/ContactUs',
    name: 'About_Contact',
    component: About_Contact
  },
  {
    path: '/About/FAQ',
    name: 'About_FAQ',
    component: About_FAQ
  },
  {
    path: '/About/HowToCite',
    name: 'About_HowToCite',
    component: About_HowToCite
  },
  {
    path: '/About/TermsOfUse',
    name: 'About_TermsOfUse',
    component: About_TermsOfUse
  },
  {
    path: '/About/Licenses',
    name: 'About_Licenses',
    component: About_Licenses
  },
  {
    path: '/About/VersionControl',
    name: 'About_VersionControl',
    component: About_VersionControl
  },

  {
    path: '/AMEI/Events',
    name: 'AMEI_Events',
    component: AMEI_Events
  },
  {
    path: '/AMEI/Members',
    name: 'AMEI_Members',
    component: AMEI_Members
  },
  {
    path: '/AMEI/Supports',
    name: 'AMEI_Supports',
    component: AMEI_Supports
  },
  {
    path: '/AMEI/Vision',
    name: 'AMEI_Vision',
    component: AMEI_Vision
  },

  {
    path: '/Crop2ML/Platforms',
    name: 'Crop2ML_Platforms',
    component: Crop2ML_Platforms
  },
  {
    path: '/Crop2ML/Tools',
    name: 'Crop2ML_Tools',
    component: Crop2ML_Tools
  },
  {
    path: '/Crop2ML/WhatIsCyML',
    name: 'Crop2ML_WhatIsCyML',
    component: Crop2ML_WhatIsCyML
  },
  {
    path: '/Crop2ML/WhatIsCrop2ML',
    name: 'Crop2ML_WhatIsCrop2ML',
    component: Crop2ML_WhatIsCrop2ML
  },

  {
    path: '/LegalMentions',
    name: 'LegalMentions',
    component: LegalMentions
  },

  {
    path: '/PrivacyNotice',
    name: 'PrivacyNotice',
    component: PrivacyNotice
  },

  {
    path: '/Home',
    name: 'Home',
    component: Home
  },
  {
    path: '/Repository',
    name: 'Repository',
    component: Repository
  },
  {
    path: '/cropmdb/:id',
    name: 'IdToNameRedirection',
    component: IdToNameRedirection,
  },
  {
    path: '/Model/:modelid',
    name: 'Model',
    component: ModelDetails,
    props:true
  },
  {
    path: '/Submit',
    name: 'Submit',
    component: Submit,
    meta: { requiresAuth: true }
  },
  {
    path: '/Edit/:modelid',
    name: 'Edit',
    component: Submit,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/SignIn',
    name: 'SignIn',
    component: SignIn
  },
  {
    path: '/SignOut',
    name: 'SignOut',
    component: SignOut
  },
  {
    path: '/Register',
    name: 'Register',
    component: Register
  },
  {
    path: '/ValidateRegistration',
    name: 'ValidateRegistration',
    component: Register
  },
  {
    path: '/Profile/:username',
    name: 'Profile',
    component: ProfileDetails
  },
  {
    path: '/EditProfile',
    name: 'EditProfile',
    component: EditProfile,
    meta: { requiresAuth: true }
  },
  {
    path: '/ForgotPassword',
    name: 'ForgotPassword',
    component: ForgotPassword
  },
  {
    path: '/ResetPassword',
    name: 'ResetPassword',
    component: ResetPassword
  },
  {
    path: '/Communities',
    name: 'Communities',
    component: Communities
  },
  {
    path: '/NewCommunity',
    name: 'NewCommunity',
    component: EditCommunity,
    meta: { requiresAuth: true }
  },
  {
    path: '/EditCommunity',
    name: 'EditCommunity',
    component: EditCommunity,
    props:true,
    meta: { requiresAuth: true }
  },
  {
    path: '/Community/:community',
    name: 'Community',
    component: CommunityDetails,
    props:true
  },
  { 
    path: '/:pathMatch(.*)*',
    name: 'PageNotFound', 
    component: PageNotFound 
  },

]


const router = new Router({
  mode: "history",
  routes
});

router.beforeEach(async (to, from, next) => {
  if (to.name === from.name) {
    return next();
  }
  const token = sessionStorage.getItem("token")
  if (token) {
    try {
      const response = await axios.post(url + "auth/checkToken",{ 
        headers: {
            Authorization: `Bearer ${token}`
        }
      })
      
      if (response.data.valid ) {
        if (store.state.user === null) {
          store.commit('setUser', response.data.user);
        }
        next(); 
      }
    } catch (error) {
      console.error("Token validation failed:", error);
      sessionStorage.removeItem("token")
      next({
        path: '/SignIn',
        //query: { redirect: to.fullPath } // Enregistre la route actuelle pour y revenir après le login
      });
    }
  } else if (to.meta.requiresAuth) {
    next({
      path: '/SignIn',
      query: { redirect: to.fullPath } // Enregistre la route actuelle pour y revenir après le login
    });
  } else {
    next();
  }
});

export default router;
