const axios = require('axios');
import config from '../config'

var url = `${config.server.protocol}://${config.server.host}:${config.server.port}/server/`;

class CommunityServices{
    
    static loadAllCommunities = async() =>{
        const res = await axios.get(url + "community/getAllCommunities")
        return res.data
    }
    
    static createOrUpdateCommunity = async (file, communityInfo) =>{
        const formData = new FormData();
        formData.append("picture", file);
        formData.append("body", JSON.stringify(communityInfo));
        return await axios.post(url+'community/createOrUpdateCommunity', formData, {
            // headers: formData.getHeaders()
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `${sessionStorage.getItem("token")}`
            }
        })
    }

    static async updateCommunity (communityid, payload){
            let apiRoute = "community/updateCommunityAsAnonymous"
            let body ={
                communityid: communityid,
                payload: payload
            }
            const res = await axios.post(url + apiRoute, body, {
                headers: {
                    'Authorization': `${sessionStorage.getItem("token")}`
                    }
            })
            return res.data
        }

    static async deleteCommunityById (communityid){
        let body ={
            communityid: communityid
        }
        return await axios.post(url + "community/deleteCommunityById", body, {
            headers: {
                'Authorization': `${sessionStorage.getItem("token")}`
                }
        })
    }
}


export default CommunityServices