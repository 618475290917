<template>

  <center>
    <b-card
      style="max-width: 40rem; margin-top: 1em;"
      class="mb-2"
    >
      <b-card-img 
        id="displayedImg"
        :src="getPicturePath(userPicture)" 
        style="max-width: 150px" 
        alt="User"
        top>
      </b-card-img>

      <h3>{{userName}}</h3>

      <div v-if="$store.getters.getUser !==null">
      
      <!--b-form @submit.stop.prevent="updateProfile" -->
      <b-input-group prepend="Email" style="margin-top:1em">
        <b-form-input
          v-model="email"
          type="text"
          disabled
        >
        </b-form-input>
      </b-input-group>

      <b-input-group prepend="Username" style="margin-top:1em">
        <b-form-input
          v-model="userName"
          type="text"
          disabled
        >
        </b-form-input>
      </b-input-group>

      <b-input-group prepend="First name" style="margin-top:1em">
        <b-form-input
          v-model="firstName"
          type="text"
          :state="stringIsNotEmpty(firstName)"
        >
        </b-form-input>
      </b-input-group>

      <b-input-group prepend="Last name" style="margin-top:1em">
        <b-form-input
          v-model="lastName"
          type="text"
          :state="stringIsNotEmpty(lastName)"
        >
        </b-form-input>
      </b-input-group>

      <b-input-group prepend="Profile picture" style="margin-top:1em">
      <b-form-file 
        id="inputImgFileForm"
        v-model="inputImgFile"
        :state="imageValidation"
        placeholder="Select or drop img (.jpg, .png, .gif) here"
        drop-placeholder="Drop an image (.jpg, .png, .gif) here ..."
        accept=".jpg, .png, .gif"
        @change="previewInputImgFile()"
        >
      </b-form-file>
      </b-input-group>

      <div v-if="fileErrorMsg">
        <p style="color:red;">
          {{fileErrorMsg}}
        </p>
      </div>

      <b-input-group prepend="City" style="margin-top:1em">
        <b-form-input
          placeholder="(optional)"
          v-model="city"
          type="text"
        >
        </b-form-input>
      </b-input-group>

      <b-input-group prepend="Country" style="margin-top:1em">
        <b-form-input
          placeholder="(optional)"
          v-model="country"
          type="text"
        >
        </b-form-input>
      </b-input-group>

      <b-input-group prepend="Institution" style="margin-top:1em">
        <b-form-input
          placeholder="(optional)"
          v-model="institution"
          type="text"
        >
        </b-form-input>
      </b-input-group>

      <b-input-group prepend="Department" style="margin-top:1em">
          <b-form-input
            placeholder="(optional)"
            v-model="department"
            type="text"
          >
          </b-form-input>
        </b-input-group>

        <b-input-group prepend="Short bio" style="margin-top:1em">
          <b-form-textarea
            placeholder="(optional)"
            v-model="shortBio"
            rows="3"
            max-rows="6"
          ></b-form-textarea>
        </b-input-group>

        <b-input-group prepend="Home page" style="margin-top:1em">
          <b-form-input
            placeholder="(optional)"
            v-model="homePage"
            type="text"
          >
          </b-form-input>
        </b-input-group>

        <b-input-group style="padding-top: 1em;" prepend="Personal keywords">
          <b-form-tags class="text-capitalize"
            v-model="keywords"
            separator=","
            placeholder="Enter new keywords separated by comma"
          ></b-form-tags>
        </b-input-group>

      <b-button variant="secondary" @click="flipShowPassword()" style="margin-top:1em">
        {{`${showPassword?'-':'+'} Change password`}}
      </b-button>

      <div v-if="showPassword">

      <b-input-group prepend="New password" style="margin-top:1em">
          <b-form-input
            :placeholder="password1"
            v-model="password1"
            type="password"
          >
          </b-form-input>
        </b-input-group>

        <b-input-group prepend="Retype password" style="margin-top:1em">
          <b-form-input
            :placeholder="password2"
            v-model="password2"
            type="password"
          >
          </b-form-input>
        </b-input-group>

        <div v-if="passwordErrorMsg">
          <p style="color:red;">
            {{passwordErrorMsg}}
          </p>
        </div>

      </div>

      <br>

      <!--b-button type="submit" variant="primary" style="margin-top:1em"-->
      <b-button type="submit" variant="primary" style="margin-top:1em" @click="updateProfile">
        Update
      </b-button>

      <!--/b-form-->

      <div v-if="profileErrorMsg">
        <p style="color:red;">
          {{profileErrorMsg}}
        </p>
      </div>
  
      <div v-if="profileSuccessMsg">
          <!-- {{profileSuccessMsg}} -->
          <p style="color:green;">
            Profile updated 
          </p>
      </div>

      <div>
        <b-button @click="$router.go(-1)" style="margin-top:1em">
          Cancel
        </b-button>
      </div>
      
      </div>
      <div v-else>
        <h2> <b-link :to="'/SignIn'"> Sign In</b-link> to edit profile</h2>
      </div>  

    </b-card>
  </center>
</template>

<script>

//import AuthServices from "../../services/AuthServices";
const path = require('path');

export default {
  name: "EditProfile",
  components: {},

  props: {
    userId: String,
  },

  

  data() {
    return {
      regLink: /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i,
      firstName: "",
      lastName: "",
      userName: null,
      userPicture:null,
      defaultPicture: "community_iconfinder_128px.png",
      inputImgFile: null,
      maxSize:5000000, // 5Mb
      city: null,
      country: null,
      institution: null,
      department: null,
      shortBio: null,
      homePage: null,
      keywords:[],
      email:null,
      fileErrorMsg: null,
      showPassword:false,
      password1:"",
      password2:"",
      passwordErrorMsg: null,
      profileErrorMsg:null,
      profileSuccessMsg:null,
    };
  },

  created() {
  },

  mounted() {
    if (this.$store.state.user === null) {
      this.$router.push("/SignIn");
    }else{
      let user = this.$store.getters.getUser
      this.firstName = user.firstName
      this.lastName = user.lastName
      this.userName = user.userName
      this.userPicture = user.picture || this.defaultPicture
      this.city = user.city
      this.country = user.country
      this.institution = user.institution
      this.department = user.department
      this.shortBio = user.shortBio
      this.homePage = user.homePage
      this.keywords = user.keywords
      this.email = user.email
    }
  },
  computed: { 

    validForm(){
      return this.email.length>0 && this.firstName.length>0 && this.lastName.length>0
    },
    imageValidation(){
      return !this.inputImgFile || this.inputImgFile && this.inputImgFile.size < this.maxSize
    },
  },
  methods: {

    flipShowPassword(){
      this.showPassword =  !this.showPassword
    },
    previewInputImgFile() {
      var file = document.getElementById('inputImgFileForm').files[0];
      if (file.size > this.maxSize) {
        this.fileErrorMsg = 'file too big, max size: '+this.maxSize/1000000+ ' Mo'
        return;
      }
      var reader  = new FileReader();
      reader.onload = function(e)  {
          var image = document.getElementById("displayedImg");
          image.src = e.target.result;
        }
      reader.readAsDataURL(file);
    },
    getPicturePath(picture){
      return `${this.$baseUrl}/user_images/` + picture
    },
    async updateProfile() {
      this.passwordErrorMsg = ""
      this.profileErrorMsg = ""
      this.profileSuccessMsg = ""
      let goOn = true
      try {
        if(!this.imageValidation){
          this.profileErrorMsg = "image file too big"
          goOn = false
        }
        if(!this.validForm){
          this.profileErrorMsg = "some required fields are empty"
          goOn = false
        }

        if(this.passwordModified() && !this.validPasswords()){
          this.passwordErrorMsg = "required passwords should be equal"
          goOn = false
        }

        if (!goOn) return

        let userProfileDetails = {
          firstName: this.firstName,
          lastName: this.lastName,
          userName: this.userName,
          city: this.city,
          country: this.country,
          institution: this.institution,
          department: this.department,
          shortBio: this.shortBio,
          homePage: this.homePage,
          keywords: this.keywords,
          email: this.email,
        }

        let image = this.inputImgFile;
        if (image != null){
          var extension = "." + image.name.split('.').pop();
          var name = path.basename(image.name, extension)
          userProfileDetails.picture = name + Date.now() + extension
        }

        if(this.passwordModified()){
          userProfileDetails['password']=this.password2
        }
        this.$store.dispatch('updateProfile',{image, userProfileDetails})

        this.profileSuccessMsg= "Profile successful"
        this.$router.push({name: 'Profile', params: { username : this.userName }})
        
      } catch (error) {
        console.log(error)
        this.profileErrorMsg = error.message;
        throw(error)
      }
    },
    stringIsNotEmpty(string){
      return string.length>0
    },
    passwordModified(){
      return this.password1.length>0 || this.password2.length>0
    },

    validPasswords(){
      return this.password1.length>0 && this.password1 === this.password2;
    },
  },

  watch:{

    email(){
      if (this.validForm)
        this.profileErrorMsg=""
    },
    firstName(){
      if (this.validForm)
        this.profileErrorMsg=""
    },
    lastName(){
      if (this.validForm)
        this.profileErrorMsg=""
    },
    password1(){
      if (this.validPasswords())
        this.passwordErrorMsg=""
    },
    password2(){
      if (this.validPasswords())
        this.passwordErrorMsg=""
    },
  },

};
</script>
<style scoped>
.global{padding: 10px;}
tr:nth-of-type(odd) {
  background: #eee;
}
th {
  background: #333;
  color: white;
  font-weight: bold;
}
td,
th {
  padding: 6px;
  border: 1px solid #ccc;
  text-align: left;
}
</style>
