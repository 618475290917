<template>

  <div v-if="isDataLoaded && compoModel" style="display:block; margin-bottom:100px" >

    <div class="row"  > 
      <!-- barre latérale gauche-->
      <div class="col-lg-3">
        <b-card style="text-align : left;">
          <b-card-text>

            <b-button :to="'/Repository'" style="margin-bottom: 20px;">Back to catalog</b-button>
            
            <!--h4 @click="showModelInfo(null, true)" style="cursor: pointer;">Meta</h4-->
            <h4>Meta</h4>
            <p @click="showModelInfo(null, true)" style="padding-left: 20px; cursor: pointer;">
              Metadata
            </p>

            <h4>ModelComposite</h4>
            <p @click="showModelInfo(null, false)" style="padding-left: 20px; cursor: pointer;">
              {{ compoModel.Attributs.name }}
            </p>

            <h4>ModelUnits</h4>
            <p v-for="(v,k) in compoModel.Composition.Model" v-bind:key="k"
            style="padding-left: 20px; margin-bottom: 0; cursor: pointer;"
            @click="showModelInfo(v.Attributs.id, false)">
            {{ v.Attributs.name }}
            </p>
          
          </b-card-text>
        </b-card>
      </div>


      <!-- contenu central -->
      <div id="modelContent"   class="col-lg-7"  >
        <model-card 
          class="cardWidth mb-4"
          :model="mainModel"
          :version="selectedModel"
          :parent="'modelDetails'"
          @delete-model="resetModel"
        />

        <div id="ModelMeta" v-if="this.showMeta">
          <b-tabs content-class="mt-3">
            <b-tab title="Overview">
              <b-table-simple class="text-left" :responsive="true" :striped="true" :hover="true">
                <b-tbody>
                  <b-tr>
                      <b-td style='font-weight:bold;'>Identifiers.org LUI</b-td>
                      <b-td>https://identifiers.org/crop2ml:{{ mainModel.identifiersLUI }}</b-td>
                  </b-tr>
                  <b-tr>
                      <b-td style='font-weight:bold;'>Submitter</b-td>
                      <b-td> 
                        <b-link :to="`/Profile/${compoModel.AddedMetadata.uploaderUsername}`"> {{ compoModel.AddedMetadata.uploaderUsername }}</b-link>
                      </b-td>
                  </b-tr>
                  <b-tr>
                      <b-td style='font-weight:bold;'>Administrators</b-td>
                      <b-td>
                        <div v-for="admin in mainModel.administratorsUsernames" :key="admin">
                          <b-link :to="`/Profile/${admin}`"> {{ admin }}</b-link>
                        </div>
                      </b-td>
                  </b-tr>
                  <b-tr v-if="mainModel.editorsUsernames.length>0">
                      <b-td style='font-weight:bold;'>Editors</b-td>
                      <b-td>
                        <div v-for="editor in mainModel.editorsUsernames" :key="editor">
                          <b-link :to="`/Profile/${editor}`"> {{ editor }}</b-link>
                        </div>
                      </b-td>
                  </b-tr>
                  <b-tr>
                      <b-td style='font-weight:bold;'>Authors</b-td>
                      <b-td>
                        <div v-for="author in metadata.Authors[0].FirstAndLastName.split(',')" :key="author">{{ author }}</div>
                      </b-td>
                  </b-tr>
                  <b-tr>
                      <b-td style='font-weight:bold;'>Encoder</b-td>
                      <b-td>
                        <div v-for="encoder in metadata.Encoders[0].FirstAndLastName.split(',')" :key="encoder">{{ encoder }}</div>
                      </b-td>
                  </b-tr>
                  <b-tr>
                      <b-td style='font-weight:bold;'>Type of model</b-td>
                      <b-td>{{ metadata.Features.TypeOfModel.Attributs.type }}</b-td>
                  </b-tr>
                  <b-tr>
                      <b-td style='font-weight:bold;'>Is part of a larger model</b-td>
                      <b-td>{{ metadata.Features.IsPartOfLargerModel.Attributs.name }}</b-td>
                  </b-tr>
                  <b-tr>
                      <b-td style='font-weight:bold;'>Algorithm programming language</b-td>
                      <b-td>
                        <div v-for="lang in programmingLanguages" :key="lang">{{ lang }}</div>
                      </b-td>
                  </b-tr> 
                  <b-tr>
                      <b-td style='font-weight:bold;'>Algorithm source for modeling platform</b-td>
                      <b-td>
                        <div v-for="platform in platformLanguages.map(l=>l.label)" :key="platform">{{ platform }}</div>
                      </b-td>
                  </b-tr>
                  <b-tr>
                      <b-td style='font-weight:bold;'>Validated model using CropMStudio</b-td>
                      <b-td>{{ metadata.Features.ValidatedWithCropMStudio.Attributs.YesOrNo }}</b-td>
                  </b-tr>
                  <b-tr>
                      <b-td style='font-weight:bold;'>License</b-td>
                      <b-td>{{ metadata.Attributs.license }}</b-td>
                  </b-tr>
                  <b-tr>
                      <b-td style='font-weight:bold;'>Source repository</b-td>
                      <b-td><b-link target='_blank' :href="metadata.Features.SourceRepository.Attributs.src" >{{ metadata.Features.SourceRepository.Attributs.src }}</b-link></b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </b-tab>
            <b-tab title="History">
              <b-table-simple class="text-left" :responsive="true" :striped="true" :hover="true">
                <b-tbody>
                  <b-tr>
                      <b-td style='font-weight:bold;'>Submitted on</b-td>
                      <b-td> {{ formatDate(mainModel.createdAt) }} </b-td>
                  </b-tr>
                  <b-tr>
                      <b-td style='font-weight:bold;'>Last modified on</b-td>
                      <b-td> {{ formatDate(mainModel.updatedAt) }} </b-td>
                  </b-tr>
                  <b-tr v-for="(v,k) in mainModel.versions" v-bind:key="k">
                      <b-td style='font-weight:bold;'>Version {{ v.Metadata.Attributs.version }} changelog</b-td>
                      <b-td>Submitted by {{ v.AddedMetadata.uploaderUsername }} on {{ formatDate(v.createdAt) }} <br/> {{ v.AddedMetadata.comments }} </b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </b-tab>
          </b-tabs>
        </div>

        <div id="ModelDetails" v-if="!showMeta">
          <b-tabs content-class="mt-3">
            
            <b-tab :title="isUnitModel ? 'Model' : 'Description'">
              <b-table-simple class="text-left" :responsive="true" :striped="true" :hover="true">
                <b-tbody>
                  <b-tr v-if="selectedModel.Description.Authors.split(',').length>0">
                      <b-td style='font-weight:bold;'>Authors</b-td>
                      <b-td>
                        <div v-for="author in selectedModel.Description.Authors.split(',')" 
                          :key="author">
                            {{author}}
                        </div>
                      </b-td>
                  </b-tr>
                  <b-tr>
                      <b-td style='font-weight:bold;'>Reference publications</b-td>
                      <b-td>
                        <div v-for="reference in selectedModel.Description.Reference.split(',')" :key="reference"><b-link target='_blank' href="reference">{{ reference }}</b-link></div>
                      </b-td>
                  </b-tr>
                  <b-tr>
                      <b-td style='font-weight:bold;'>Short description</b-td>
                      <b-td> {{ selectedModel.Description.ShortDescription }}</b-td>
                  </b-tr>
                  <b-tr>
                      <b-td style='font-weight:bold;'>Extended description</b-td>
                      <b-td> {{ selectedModel.Description.ExtendedDescription }}</b-td>
                  </b-tr>
                  <b-tr v-if="selectedModel.Function && selectedModel.Function.length>0">
                      <b-td style='font-weight:bold;'>Functions</b-td>
                      <b-td>
                        <div v-for="func in selectedModel.Function" 
                          :key="func">
                          {{ func.Attributs.name }}
                        </div>
                      </b-td>
                      
                  </b-tr>
                  <b-tr>
                      <b-td style='font-weight:bold;'>Time step</b-td>
                      <b-td> {{ timestep }}</b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </b-tab>

            <b-tab title="Inputs" v-if="exists(selectedModel.Inputs) && selectedModel.Inputs.Input.length != 0">
              <div v-if="toArrayIfNeeded(selectedModel.Inputs.Input)">
                <b-table class="text-left" :responsive="true" :striped="true" :hover="true" :items="setInputs(selectedModel.Inputs.Input)"></b-table>
              </div>
            </b-tab>

            <b-tab title="Outputs" v-if="exists(selectedModel.Outputs) && selectedModel.Outputs.Output.length != 0">
              <div v-if="toArrayIfNeeded(selectedModel.Outputs.Output)">
                <b-table class="text-left" :responsive="true" :striped="true" :hover="true" :items="setOutputs(selectedModel.Outputs.Output)"></b-table>
              </div>
            </b-tab>

            <b-tab title="Parameters" v-if="exists(selectedModel.Inputs) && selectedModel.Inputs.Input.length != 0">
              <div v-if="toArrayIfNeeded(selectedModel.Inputs.Input)">
                <b-table class="text-left" :responsive="true" :striped="true" :hover="true" :items="setParameters(selectedModel.Inputs.Input)"></b-table>
              </div>
            </b-tab>

            <b-tab title="Model view" v-if="exists(selectedModel.AddedMetadata) && exists(selectedModel.AddedMetadata.pictures)">
              <b-card v-for="picture in selectedModel.AddedMetadata.pictures" :key="picture">
                <b-card-img :src="getDocPicturePath(picture)"/>
              </b-card>
            </b-tab>

            <b-tab title="Algorithm" v-if="isUnitModel" @click="setCodeType('algo');loadCodeFile()">
              <prism-editor class="my-editor" v-model="code" :highlight="highlighter" line-numbers readonly></prism-editor>
            </b-tab>
            <b-tab title="Code" v-if="isUnitModel" @click="setCodeType('code');loadCodeFile()">
            <p> Source code : 
              <b-button class="langButton" v-for="lang in programmingLanguages" :key="lang" @click="setCodeType('code');setLang(lang);loadCodeFile()">{{ lang }} </b-button>
            </p><p> Modelling platform: 
              <b-button class="langButton" v-for="(lang,i) in platformLanguages" :key="i" @click="setCodeType('platform');setLang(lang.label);loadCodeFile()">{{ lang.label }}</b-button>
            </p><p>
              <prism-editor class="my-editor" v-model="code" :highlight="highlighter" line-numbers readonly></prism-editor>
            </p>
            </b-tab>
             <b-tab title="Functions" v-if="isUnitModel && selectedModel.Function.length>0" @click="setCodeType('function');setLang('pyx');setFunction(selectedModel.Function[0].Attributs.name);loadCodeFile()">
              <b-button class="langButton" v-for="func in selectedModel.Function" :key="func.Attributs.name" @click="setCodeType('function');setLang('pyx');setFunction(func.Attributs.name);loadCodeFile()">{{ func.Attributs.name }} </b-button>
              <prism-editor class="my-editor" v-model="code" :highlight="highlighter" line-numbers readonly></prism-editor>
            </b-tab>
          </b-tabs>
        </div>
          
      </div>

      <!-- contenu droite -->
      <div id="modelInfo" class="col-lg-2" >
        
        <div>
          <b-card sub-title="Submitter" >
            <b-card-img 
              src="/images/user_icon.png" 
              style="max-width:50px" 
              alt="User"
              top>
            </b-card-img>
            <b-card-text >
              <b-link :to="`/Profile/${compoModel.AddedMetadata.uploaderUsername}`">    
              {{compoModel.AddedMetadata.uploaderUsername}}
              </b-link>
            </b-card-text>
          </b-card>
        </div>

        <div>
          <b-card sub-title="Version" >
            <b-card-img 
                src="/images/version_iconfinder_128px.png" 
                style="max-width: 50px" 
                alt="Users"
                top>
            </b-card-img>
            <b-card-text >
              <b-form-select v-model="selectedVersion" :options="mainModel.versionsList">
              </b-form-select>
            </b-card-text>
          </b-card>
        </div>

        <div>
          <b-card v-if="mainModel.linkedCommunities && mainModel.linkedCommunities.length>0" sub-title="Community" >
            <!--b-card-img 
                src="/images/community_iconfinder_128px.png" 
                style="max-width: 50px" 
                alt="Users"
                top>
            </b-card-img-->
            <b-card-text >
              <span v-for="(community,index) of mainModel.linkedCommunities" :key="index">
                <b-link :to="{name: 'Community', params:{community:community}}">{{ community }}</b-link>
                <!-- Add a comma after each item except the last -->
                <span v-if="index < mainModel.linkedCommunities.length - 1">, </span>
              </span>
            </b-card-text>
          </b-card>
        </div>

        <div>
          <b-card sub-title="Keywords" >
            <b-card-img 
                src="/images/tag2_inconfinder_128px.png" 
                style="max-width: 50px" 
                alt="Users"
                top>
            </b-card-img>
            <b-card-text> 
              <b-badge  
                variant="secondary" 
                style="margin-right:3px;font-weight: 500;"
                v-for="(keyword,i) in compoModel.AddedMetadata.keywords" 
                :key="i" 
                @click="$router.push({name: 'Repository', query: { keyword : keyword }})"> <!---->
                {{keyword}}             
              </b-badge>
            </b-card-text>
          </b-card>
        </div>

        <!--div>
          <b-card sub-title="Favourite by" >
          <b-card-img 
                src="/images/favourite_iconfinder_128px.png" 
                style="max-width: 50px" 
                alt="Users"
                top>
            </b-card-img>
          </b-card>          
        </div-->
        
      </div>

    </div>
  </div>
  
</template>
<script>

// import StarRating from 'vue-star-rating'
import FileServices from "../../services/FileServices"
import ModelCard from './ModelCard.vue';

import { highlight, languages } from 'prismjs/components/prism-core';
import prismJson from 'prismjs/components.json';
import 'prismjs/components/prism-clike';  
import 'prismjs/components/prism-csharp';  
import 'prismjs/components/prism-c';  
import 'prismjs/components/prism-cpp';  
import 'prismjs/components/prism-javascript';
import 'prismjs/components/prism-java';
import 'prismjs/components/prism-python';
import 'prismjs/components/prism-r';
import 'prismjs/themes/prism-tomorrow.css'; // import syntax highlighting styles

export default {
  name: 'ModelDetails',
  components: {
    ModelCard,
  },
  data() {
    return {
      mainModelId: null,
      mainModel:null,
      compoModel:null,
      unitModel:null,
      isUnitModel:false,
      selectedModel:{},
      selectedVersion: null,
      showMeta: true,
      code:null,
      codeType:null,
      lang:null,
      functionName:null,
      prismLanguages:null,
      defaultLanguage:'cpp',
      platformLanguages:[
        {label:"bioma", language:"cs"},
        {label:"dssat", language:"f90"},
        {label:"openalea", language:"py"},
        {label:"record", language:"cpp"},
        {label:"simplace", language:"java"},
        {label:"sirius", language:"cs"},
        {label:"stics", language:"f90"}
      ],
      highlighterLanguages:[
        {label:"bioma", language:"cs"},
        {label:"dssat", language:"cpp"},
        {label:"f90", language:"cpp"},
        {label:"openalea", language:"py"},
        {label:"pyx", language:"py"},
        {label:"record", language:"cpp"},
        {label:"simplace", language:"java"},
        {label:"sirius", language:"cs"},
        {label:"stics", language:"cpp"}
      ]
    }
  },

  created() {
    this.prismLanguages = prismJson.languages
  },

  async mounted() {

    if (this.$route.params.modelid !== null){
      this.mainModelId = this.$route.params.modelid;
      if (this.isDataLoaded) {
        await this.selectModelById(null)
      }
    }

    await this.$store.dispatch('updateModel', {modelid:this.mainModelId, payload:{views:1}})

  },

  computed:{
    isDataLoaded() {
      return this.$store.getters.getIsDataLoaded
    },
    timestep () {
      if (this.selectedModel.Attributs.timestep === "1")
        return "Daily"
      else
        return "Hourly"
    },  
    user(){
      return this.$store.getters.getUser
    },
    models(){
      return this.$store.getters.getModels
    },
    metadata(){
      return this.compoModel.Metadata
    },
    programmingLanguages(){
      return Object.keys(this.metadata.Transformation.Languages).sort()
    },
    // platformLanguages(){
    //   return Object.keys(this.metadata.Transformation.Platforms).sort()
    // },
    
  },

  methods: {
    findAlias(data, recherche) {
    for (const key in data) {
      const alias = data[key].alias;
      
      if (alias) {
        if (typeof alias === 'string' && alias.includes(recherche)) {
          return key;
        } else if (Array.isArray(alias) && alias.some(a => a.includes(recherche))) {
          return key;
        }
      }
    }
    return null; // Si aucun alias ne correspond
    },
    highlighter(code) {
      let highlightLang = this.defaultLanguage
      const langKey = Object.keys(this.prismLanguages).includes(this.lang)
      const aliasKey =  this.findAlias(this.prismLanguages, this.lang)
      const highlighterLang = this.highlighterLanguages.find(l=> l.label === this.lang)
      const langExist = langKey || aliasKey
      if (langExist) {
        highlightLang = this.lang
      } else if (highlighterLang && typeof highlighterLang.language !== 'undefined') {
        highlightLang = highlighterLang.language
      }

      console.log('HIGHLIGHTERLANG', highlightLang)
      return highlight(code, languages[highlightLang])
    },
    getDocPicturePath(picture){
      return `${this.$baseUrl}/packages/` + this.selectedModel.AddedMetadata.packageName + '/doc/images/' + picture
    },

    exists(field){
      return !(typeof field === 'undefined')
    },

    formatDate(dateString) {
        const date = new Date(dateString);
        return new Intl.DateTimeFormat('default', {dateStyle: 'medium'}).format(date);
    },

    toArrayIfNeeded(obj){
      if(obj instanceof Array ){
        return obj
      }else{ 
        return [obj]
      }
    },

    //currentKey: the key you want to move
    //afterKey: position to move-after the currentKey, null or '' if it must be in position [0]
    //obj: object
    moveObjectElement(currentKey, afterKey, obj) {
      var result = {};
      var val = obj[currentKey];
      delete obj[currentKey];
      var next = -1;
      var i = 0;
      if(typeof afterKey == 'undefined' || afterKey == null) afterKey = '';
      Object.entries(obj).forEach(field => {
          const [k, v] = field;
          if((afterKey == '' && i == 0) || next == 1) {
              result[currentKey] = val; 
              next = 0;
          }
          if(k == afterKey) { next = 1; }
          result[k] = v;
          ++i;
      });
      if(next == 1) {
          result[currentKey] = val;
      }
      if(next !== -1) return result; else return obj;
    },

    /*preferredOrder(obj, order) {
      var newObject = {};
      for(var i = 0; i < order.length; i++) {
          // eslint-disable-next-line no-prototype-builtins
          if(obj.hasOwnProperty(order[i])) {
              newObject[order[i]] = obj[order[i]];
          }
      }
      return newObject;
    },*/

    setInputs(input_or_output){
      let array_of_obj = this.toArrayIfNeeded(input_or_output);
      let items = [];
      for(let obj of array_of_obj){
        if(obj.Attributs.inputtype == "variable"){
          let objCopy = JSON.parse(JSON.stringify(obj))
          delete objCopy.Attributs["inputtype"]
          objCopy.Attributs["category"] = objCopy.Attributs["variablecategory"]
          delete objCopy.Attributs["variablecategory"]
          objCopy.Attributs["type"] = objCopy.Attributs["datatype"]
          delete objCopy.Attributs["datatype"]
          objCopy = this.moveObjectElement("type", "name", objCopy.Attributs)
          objCopy = this.moveObjectElement("category", "type", objCopy)
          items.push(objCopy)
        }
      }
      return items;
    },

    setParameters(input_or_output){
      let array_of_obj = this.toArrayIfNeeded(input_or_output);
      let items = [];
      for(let obj of array_of_obj){
        if(obj.Attributs.inputtype == "parameter"){
          let objCopy = JSON.parse(JSON.stringify(obj))
          delete objCopy.Attributs["inputtype"]
          objCopy.Attributs["category"] = objCopy.Attributs["parametercategory"]
          delete objCopy.Attributs["parametercategory"]
          objCopy.Attributs["type"] = objCopy.Attributs["datatype"]
          delete objCopy.Attributs["datatype"]
          objCopy = this.moveObjectElement("type", "name", objCopy.Attributs)
          objCopy = this.moveObjectElement("category", "type", objCopy)
          items.push(objCopy)
        }
      }
      return items;
    },

    setOutputs(input_or_output){
      let array_of_obj = this.toArrayIfNeeded(input_or_output);
      let items = [];
      for(let obj of array_of_obj){
        let objCopy = JSON.parse(JSON.stringify(obj))
        objCopy.Attributs["category"] = objCopy.Attributs["variablecategory"]
        delete objCopy.Attributs["variablecategory"]
        objCopy.Attributs["type"] = objCopy.Attributs["datatype"]
        delete objCopy.Attributs["datatype"]
        objCopy = this.moveObjectElement("type", "name", objCopy.Attributs)
        objCopy = this.moveObjectElement("category", "type", objCopy)
        items.push(objCopy)
      }
      return items;
    },

    

    async selectModelById(unitModelId){
      let mainModel = this.models.find(m=>m.id === this.mainModelId);
      if (!mainModel) {
        const res = await this.$store.dispatch('loadPrivateModelById',this.mainModelId)
        if (res && res.response.status === 404) {
          this.$router.push({name: 'PageNotFound'})
        }
      }
      [this.isUnitModel, this.mainModel, this.compoModel, this.unitModel, this.selectedVersion] = this.$store.getters.getModelByIdAndVersion(this.mainModelId, this.selectedVersion, unitModelId)
      this.selectedModel = this.isUnitModel ? this.unitModel : this.compoModel

      this.isPublic = this.mainModel.isPublic 
    },

    showModelInfo(unitModelId, showMeta){
      this.showMeta = showMeta
      this.selectModelById(unitModelId)
    },

    showCurrentRating(rating) {
      this.currentRating = (rating === 0) ? this.currentSelectedRating : "Click to select " + rating + " stars"
    },
    
    setCurrentSelectedRating(rating) {
      this.currentSelectedRating = "You have Selected: " + rating + " stars";
    },
    setLang(lang){
      this.lang=lang
    },
    setCodeType(type){
      this.codeType=type
    },
    setFunction(func){
      this.functionName=func
    },
    async loadCodeFile(){
      const packageName=this.compoModel.AddedMetadata.packageName
      let unitModelName=this.unitModel.Attributs.name
      let lang = this.lang || this.programmingLanguages[0]
      let extension = lang
      if (!this.codeType) return;

      if (this.codeType === 'platform') {
        extension = this.platformLanguages.find(l=> l.label === lang).language
      } else if (this.codeType === 'algo' ) {
        const filepath = this.unitModel.Algorithm[0].Attributs.filename
        unitModelName = filepath.substring(filepath.lastIndexOf("/") + 1, filepath.lastIndexOf("."))
        extension = 'pyx'
        lang = 'pyx'
        //reset default code lang
        this.setLang(this.defaultLanguage)
      }
      else if (this.codeType === 'function' ) {
        const filepath = this.unitModel.Function.find(f => f.Attributs.name === this.functionName).Attributs.filename
        unitModelName = filepath.substring(filepath.lastIndexOf("/") + 1, filepath.lastIndexOf("."))
        extension = 'pyx'
        //reset default code lang
        this.setLang(this.defaultLanguage)
      }

      console.log('url', packageName, unitModelName, this.codeType,lang, extension)
      
      try {
        this.code = await FileServices.loadCodeFile(packageName, unitModelName, this.codeType, lang, extension)
      } catch (err) {
        console.error('Erreur:', err);
      }
    },
    resetModel(){
      this.$router.push('/Repository')
    },
  },

  watch:{
    selectedVersion: function(){
      this.selectModelById()
    },
    unitModel: function(){
      this.loadCodeFile()
    },
    lang: function(){
      this.loadCodeFile()
      this.highlighter(this.code)
      

    },
    isDataLoaded(newVal) {
      if (newVal) {
        this.selectModelById(null)
      }
    },
  },

}
</script>

<style>
.tree-node-children .tree-node-label span {
  font-size: small;
}
</style>

<style scoped>

p{
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 2px;
  padding-right: 2px;
}

.langButton{
  margin-right: 5px;
  margin-left: 5px;
  color: #037bff;
  background-color: #fff;
}

 /* Prism required class */
  .my-editor {
    /* we dont use `language-` classes anymore so thats why we need to add background and text color manually */
    background: #f7f7f7;
    color: #ccc;
    /* Enable vertical scrolling */
    overflow-y: auto; 
    height: 600px; /* Fix the height */
    resize: none; /* Prevent resizing by the user */
    /* you must provide font-family font-size line-height. */
    font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
    font-size: 14px;
    line-height: 1.5;
    padding: 5px;
    margin-top: 10px;
  }

  /* optional class for removing the outline */
  .prism-editor__textarea:focus {
    outline: none;
  }

</style>
