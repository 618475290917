import Vue from 'vue'
import App from './App.vue'
import store from "./store";
import router from './router'
import config from './config'
import axios from 'axios';

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'


import { PrismEditor } from 'vue-prism-editor';
import 'vue-prism-editor/dist/prismeditor.min.css'; // import the styles

import Notifications from 'vue-notification'


Vue.use(Notifications)
Vue.component('PrismEditor', PrismEditor);
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.prototype.$baseUrl = `${config.server.protocol}://${config.server.host}:${config.server.port}`

Vue.config.productionTip = false

axios.interceptors.response.use(
  response => response, // Retourne la réponse si pas d'erreur
  error => {
    //store.commit('setError', { status: error.response.status, message: error.response.message });
    console.log('axios intercept', error.response,error.response.data.message );
    const message = error.response.data.message || error.response.statusText
    store.dispatch('showError', message);
    if (error.response && error.response.status === 404) {
      return Promise.reject(error);
    }
  }
);

new Vue({
  store,router,
  render: h => h(App),
}).$mount('#app')