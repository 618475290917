<template>
    
     
    <!--div class="card-body">
      <img :src="getPicturePath('community', community.picture)" class="card-img-top" style="max-width: 70px;" alt="Community image" />
      <router-link :to="{ name: 'Community', params:{community:community.name} }">
        <h5 class="card-title" >{{ community.name }}</h5>
      </router-link>
      <p class="card-text">{{ community.description }}</p>
    </div-->

    <b-card 
      :title="community.name"
      @click="$router.push({name:'Community', params: {community:community.name} })"
    >
      <b-row no-gutters>
        <b-col lg="3" >
            <b-card-img :src="getPicturePath(community.picture)" style="max-heigth:100px; max-width:100px"   alt="Community Preview" ></b-card-img>
        </b-col>
        <b-col lg="9">
          <b-card-text v-if="community.description && community.description.length>120" class="text-left ml-4">
            {{community.description.slice(0, 120)}} ...
          </b-card-text>
          <b-card-text v-else class="text-left ml-4">
            {{community.description}}
          </b-card-text>

          <!--div v-if="parent==='profile'">
              <b-badge v-if="community.administratorsUsernames && community.administratorsUsernames.includes(userProfile.userName)" variant="info" style="margin-right:3px;">
                Aministrator
              </b-badge>
              <b-badge v-if="community.starredByUsernames && community.starredByUsernames.includes(userProfile.userName)" variant="warning" style="margin-right:3px;">
                <b-icon icon="star"></b-icon> Starred
              </b-badge>
          </div-->
        </b-col>

      </b-row>
    </b-card>
      
</template>
<script>


export default {
  name: 'CommunityCard',
  props: {
    community: {
      type: Object,
      default: null,
    },
    parent: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
    }
  },
  created() {
  },

  mounted() {
  },

  computed:{
    user(){
      return this.$store.getters.getUser
    },
    userProfile(){
      return this.$store.getters.getUserProfile
    },
    isLogged(){
      return this.user !== null
    },
    isAdmin(){      
      return this.isLogged && this.community.administrators.includes(this.user.email)
    }
  },
  methods: {
    getPicturePath(picture){
      return `${this.$baseUrl}/community_images/` + picture
    },
  },

  watch:{
  },

}
</script>
<style scoped>

@import "https://cdn.jsdelivr.net/npm/@voerro/vue-tagsinput@2.7.1/dist/style.css";

.caption {
  font-size: smaller;
  color: grey;
  padding-top: 0px;
  text-align: left;
  margin-bottom: 0px;
}

</style>