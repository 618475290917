<template>
    <div class="page-404">
      <div class="container">
        <h1>404</h1>
        <p>Oups, nothing there! The page you are looking for does not exist.</p>
        <router-link to="/" class="back-home">Back</router-link>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "PageNotFound",
  };
  </script>
  
  <style scoped>
  .page-404 {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #ffffff;
    color: #333;
    font-family: Arial, sans-serif;
  }
  .container {
    text-align: center;
  }
  h1 {
    font-size: 6em;
    margin: 0;
    color: #ff6347;
  }
  p {
    font-size: 1.5em;
    margin: 20px 0;
  }
  .back-home {
    display: inline-block;
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 1.2em;
    color: white;
    background-color: #007bff;
    text-decoration: none;
    border-radius: 5px;
  }
  .back-home:hover {
    background-color: #0056b3;
  }
  </style>
  