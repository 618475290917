<template>

  <div class="container">
    <b-row>
      <b-col>
        <h1 class="heading">
          The Agricultural Model Exchange Initiative (AMEI)
        </h1>
      </b-col>
    </b-row>
    <b-row class="text-left" style="margin-top: 20px">
      <b-col>
        <h3>
          Background
        </h3>
        <p style="text-align: justify;">
          Process-based crop simulation models (CSMs) are dynamic computational tools that simulate the development and
          growth of a crop in relation to environmental conditions (e.g. air temperature, soil water, evaporative
          demand, and atmospheric CO2 concentration) and management practices (e.g. sowing date, N fertilizer
          application, and crop residue).Their development has essentially been motivated by both their capacity to
          aggregate our ever-growing knowledge of crop functioning, and by the need to provide informed support for
          decisions/policy.
        </p>
        <p style="text-align: justify;">
          At the same time, and without much interaction with the crop-modelling community, various groups have
          developed plant models with an explicit description of 3-dimensional (3D) morphology and form of plants, known
          as functional–structural plant models (FSPM; <b>Evers et al., 2018</b>). In contrast with CSMs, which
          generally model
          processes at the canopy level, FSPMs consider processes at the level of individual plants and/or organs, from
          which complex properties at the plant community level emerge. Different FSPMs have a focus either on the
          above- or below-ground parts of the plant. Most of them do not consider the whole plant life cycle, and rather
          a limited number of processes, depending on the research questions being addressed. However, the breadth of
          processes considered in FSPMs has greatly increased in recent years, which now allow them to address questions
          related to the interactions of crops with environment and management that could so far only be addressed by
          CSMs (Evers et al., 2018). CSMs and FSPMs are complementary and can inform each other on processes acting on
          different scales and both types of models are considered in this special issue. However, the effective
          coupling of CSMs and FSPMs is often tedious and limited to use cases with no application.
        </p>
        <p style="text-align: justify;">
          The first computational models of crop and soil processes were developed more than 60 years ago (<b>Jones et
          al., 2017</b>). Their long history has produced a large number of models. For instance, there are more than 40
          wheat
          CSMs currently in use (<b>Asseng et al., 2019b</b>). Most models have evolved from a few landmark models such
          as
          CERES and EPIC in the USA or SUCROS in Europe, as illustrated in the figure below.
        </p>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-img
            src="/images/crop_simulation_models.png"
            fluid
        />
        <br>
        <small>Genealogy of the main process-based crop simulation models. Source:
          <b-link
              target="_blank"
              href="https://doi.org/10.1093/jxb/erz175"
              class="text-decoration-none">Muller & Martre (2019)</b-link>.</small>
      </b-col>
    </b-row>
    <b-row class="text-left" style="margin-top: 20px">
      <b-col>
        <p style="text-align: justify;">
          CSMs differ according to their choice of relationships and hypotheses regarding process functioning and
          feedback loops and their combinations of mechanistic components. Since 2010, the AgMIP (<b>Rosenzweig et al.,
          2013</b>) and MACSUR (<b>Rötter et al., 2013</b>) networks of crop modelers have carried out several landmark
          CSM
          inter-comparison studies. These studies clearly highlighted both the capacities and the limits of current CSMs
          (<b>Asseng et al., 2013; Bassu et al., 2014; Li et al., 2015; Ruane et al., 2017</b>) and have led to
          important model
          improvements (<b>Maiorano et al., 2017; Wang et al., 2017</b>).
        </p>
        <p style="text-align: justify;">
          CSMs are often developed in modeling platforms to ensure their future extension and to couple different crop
          models with a soil model and a crop management event scheduler. They provide robust solutions to execute and
          interact with models to predict the impact of climate change, innovative crop management practices, and new
          trait- or gene-based breeding technologies on the production of crops and agricultural systems. However, the
          intercomparison and improvement of crop simulation models is difficult due to the lack of efficient methods
          for exchanging biophysical processes between modeling platforms.
        </p>
        <p style="text-align: justify;">
          The groups represented in the Agricultural Model Exchange Initiative (AMEI) have all developed advanced
          solutions for modular modeling. APSIM proposes a solution that is dependent on its architecture. RECORD
          proposes a solution based on DEVS (Discrete Event System Specification) formalism, which is intrinsically a
          modular and hierarchical formalism for modeling. OpenAlea has developed solutions to reuse and integrate in
          scientific workflows existing models independently of the programming language used. BioMA uses a platform
          independent “layered” approach. SIMPLACE XXXXXXX. However, none of them have developed an easy solution to
          exchange model components between simulation frameworks and platforms.
        </p>
        <p style="text-align: justify;">
          Partners of the Agricultural Model Exchange Initiative share the following commun vision and goals.
        </p>
      </b-col>
    </b-row>
    <b-row class="text-left" style="margin-top: 20px">
      <b-col>
        <h3>
          Vision
        </h3>
        <b-list-group flush>
          <b-list-group-item class=" flex-column align-items-start py-4 px-4">
            <div class="d-flex w-100 justify-content-between">
              <div>
                <div class="d-flex w-100 align-items-start">
                  <h5 class="mb-1">The participating institutions share a common vision to advance agro-environmental
                    research via the use of simulation modeling to:
                  </h5>
                </div>
              </div>
            </div>
            <b-list-group>
              <b-list-group-item class="flex-column align-items-start">
                <p class="text-left mb-1">
                  increase capabilities and responsiveness to stakeholder’ needs;
                </p>
              </b-list-group-item>

              <b-list-group-item class="flex-column align-items-start">
                <p class="text-left mb-1">
                  leverage the expertise of third parties;
                </p>
              </b-list-group-item>

              <b-list-group-item class="flex-column align-items-start">
                <p class="text-left mb-1">
                  realize the benefit of sharing and complementing different expertise.
                </p>
              </b-list-group-item>
            </b-list-group>
          </b-list-group-item>
        </b-list-group>
        <p style="text-align: justify;">
          Given this common ground, the participating institutions share a set of motivations to improve model
          modularity and reuse, and enhance collaborations between research groups through documented and shareable
          model components following FAIR principals.
        </p>
      </b-col>
    </b-row>
    <b-row class="text-left" style="margin-top: 20px">
      <b-col>
        <h3>
          Motivations
        </h3>
        <b-list-group flush>
          <b-list-group-item class=" flex-column align-items-start py-4 px-4">
            <p class="text-left mb-1">
              facilitate model intercomparison (at the process level) and model improvement through the exchange of
              model components (algorithms) and code reuse between platforms/models;

            </p>
          </b-list-group-item>
          <b-list-group-item class=" flex-column align-items-start py-4 px-4">
            <p class="text-left mb-1">
              bridge the gap between ecophysiologists who develop models at the process level and crop modelers and
              model users and facilitate the integration in crop models of new knowledge in plant science (i.e. we are
              seeking the exchange of knowledge rather than black box models);
            </p>
          </b-list-group-item>
          <b-list-group-item class=" flex-column align-items-start py-4 px-4">
            <p class="text-left mb-1">
              provide a solution to the agro-environmental research community for NexGen crop models.
            </p>
          </b-list-group-item>
        </b-list-group>
      </b-col>
    </b-row>
    <b-row class="text-left" style="margin-top: 20px">
      <b-col>
        <h3>
          Solutions
        </h3>
        <b-list-group flush>
          <div class="d-flex w-100 justify-content-between">
            <div>
              <div class="d-flex w-100 align-items-start">
                <h5 class="mb-1">The following solutions can be considered to exchange models between modeling
                  frameworks and simulation
                  platforms:
                </h5>
              </div>
            </div>
          </div>
          <b-list-group-item class=" flex-column align-items-start py-4 px-4">
            <div class="d-flex w-100 justify-content-between">
              <div>
                <div class="d-flex w-100 align-items-start">
                  <h5 class="mb-1">a. Develop wrappers :
                  </h5>
                </div>
              </div>
            </div>
            <b-list-group>
              <b-list-group-item class="flex-column align-items-start">
                <p class="text-left mb-1">
                  Even if automatic wrapping solutions exist (e.g. swing), these solution are not practical because 1)
                  the number of wrappers is a factorial of the number of platforms involved; and 2) language
                  incompatibility makes it very difficult to exchange coded algorithms.
                </p>
              </b-list-group-item>
            </b-list-group>
          </b-list-group-item>
          <b-list-group-item class=" flex-column align-items-start py-4 px-4">
            <div class="d-flex w-100 justify-content-between">
              <div>
                <div class="d-flex w-100 align-items-start">
                  <h5 class="mb-1">b. Use of a component framework and tools to develop components:
                  </h5>
                </div>
              </div>
            </div>
            <b-list-group>
              <b-list-group-item class="flex-column align-items-start">
                <p class="text-left mb-1">
                  Based on past experiences, the acceptance of a single framework and platform by the crop modeling
                  community is very unlikely.
                </p>
              </b-list-group-item>
              <b-list-group-item class="flex-column align-items-start">
                <p class="text-left mb-1">
                  The exchange of binaries will be difficult when there are differences of language.
                </p>
              </b-list-group-item>
            </b-list-group>
          </b-list-group-item>
          <b-list-group-item class=" flex-column align-items-start py-4 px-4">
            <div class="d-flex w-100 justify-content-between">
              <div>
                <div class="d-flex w-100 align-items-start">
                  <h5 class="mb-1">c. Develop a declarative language to standardize the definition of algorithms and the
                    composition of components. Here we propose:
                  </h5>
                </div>
              </div>
            </div>
            <b-list-group>
              <b-list-group-item class="flex-column align-items-start">
                <p class="text-left mb-1">
                  to adopt a declarative language and recommendations on its use to develop algorithms at fine
                  granularity;

                </p>
              </b-list-group-item>
              <b-list-group-item class="flex-column align-items-start">
                <p class="text-left mb-1">
                  each framework development team writes a translator that converts the declarative algorithms defined
                  above into models for the specific framework;
                </p>
              </b-list-group-item>
              <b-list-group-item class="flex-column align-items-start">
                <p class="text-left mb-1">
                  to develop tools and guidelines to convert the above algorithms into documentation;
                </p>
              </b-list-group-item>
              <b-list-group-item class="flex-column align-items-start">
                <p class="text-left mb-1">
                  to create an accessible repository (aka CropMRepository) of exchangeable model components.
                </p>
              </b-list-group-item>
            </b-list-group>
          </b-list-group-item>
        </b-list-group>
        <p style="text-align: justify;">
          AMEI members all agree that solution c should be preferred and that : (i) the declarative language should be
          compatible with a data structure (e.g. Multiscale-Tree Graph, <b-link
              target="_blank"
              href="https://mtg.readthedocs.io"
              class="text-decoration-none">MTG</b-link>) that allows the communication between
          components through the data structure (rather than between components) to facilitate multiscale modeling; (ii)
          the standard should be based on stateless classes and composition pattern, (iii) all developments,
          specifications and documentations should be open source and hosted on GitHub.
        </p>
        <p style="text-align: justify;">
          The standard (aka Crop2ML) is not a piece of software, rather a way of building framework independent model
          parts. It allows not only the exchange and composition of models at fine granularity but also of modeling
          solutions (i.e. crop growth models).
        </p>
      </b-col>
    </b-row>
  </div>

</template>
<script>


export default {
  name: 'AMEI_Vision',

  data() {
    return {}
  },

  components: {},

  async created() {

  },

  async mounted() {

  }

}
</script>

<style scoped>

ul.myListStyle {
  list-style-type: none;
}

#about {
  height: 80vh;
  width: 100%;
}

#recentModels {
  text-align: left;
}


</style>
